import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform, useAnimation } from 'framer-motion';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import SkillsSection from '../components/SkillsSection';
import CertificationsGrid from '../components/CertificationsGrid';
import AISection from '../components/AISection';
import CallToAction from '../components/CallToAction';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: theme.palette.mode === 'dark' ? '#0A0A0A' : '#F5F5F5',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  position: 'relative',
  marginTop: 0,
  paddingTop: 0,
}));

const HeroSection = styled(Box)(({ theme }) => ({
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.mode === 'dark' 
      ? 'radial-gradient(circle at 50% 50%, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 100%)'
      : 'radial-gradient(circle at 50% 50%, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)',
    zIndex: 1,
    transition: 'background 0.3s ease'
  },
  '&.mouse-moving::after': {
    background: theme.palette.mode === 'dark' 
      ? 'radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 100%)'
      : 'radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)'
  }
}));

const ProfileImage = styled(motion.img)(({ theme }) => ({
  position: 'absolute',
  top: '8%',
  right: '-10%',
  transform: 'translateY(50%)',
  height: '100vh',
  width: 'auto',
  objectFit: 'cover',
  objectPosition: 'center right',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    right: '-22%',
    height: '100vh',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

const HeroContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  maxWidth: '850px',
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
    maxWidth: '100%',
  }
}));

const ScrollingText = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '20vw',
  fontWeight: 900,
  opacity: 0.03,
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
}));

const ContentSection = styled(motion.section)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(20, 0)
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '32px',
  width: 'auto',
  borderRadius: '0 !important',
  filter: theme.palette.mode === 'light' ? 'invert(1)' : 'none'
}));

const About = () => {
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const { scrollYProgress: mainScrollProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"]
  });

  const backgroundY = useTransform(mainScrollProgress, [0, 1], ['0%', '100%']);
  const textX = useTransform(mainScrollProgress, [0, 1], ['0%', '-50%']);

  const handleMouseMove = (e) => {
    const element = e.currentTarget;
    if (!element.classList.contains('mouse-moving')) {
      element.classList.add('mouse-moving');
    }
    const { clientX, clientY } = e;
    const { left, top, width, height } = element.getBoundingClientRect();
    const x = ((clientX - left) / width) * 100;
    const y = ((clientY - top) / height) * 100;
    element.style.setProperty('--mouse-x', `${x}%`);
    element.style.setProperty('--mouse-y', `${y}%`);
  };

  const [clients, setClients] = useState([]);
  const [awards, setAwards] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientsRes, awardsRes] = await Promise.all([
          axios.get('/api/clients'),
          axios.get('/api/awards')
        ]);
        
        const sortedClients = clientsRes.data.sort((a, b) => a.name.localeCompare(b.name));
        setClients(sortedClients);
        setAwards(awardsRes.data);
        console.log('Awards;', awardsRes.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []);

  const containerRef = useRef(null);
  const { scrollYProgress: columnsScrollProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const firstColumnControls = useAnimation();
  const secondColumnControls = useAnimation();
  const thirdColumnControls = useAnimation();

  useEffect(() => {
    const unsubscribe = columnsScrollProgress.on("change", (value) => {
      if (value > 0.2) {
        firstColumnControls.start({ opacity: 1, y: 0 });
      }
      if (value > 0.4) {
        secondColumnControls.start({ opacity: 1, y: 0 });
      }
      if (value > 0.6) {
        thirdColumnControls.start({ opacity: 1, y: 0 });
      }
    });

    return () => unsubscribe();
  }, [columnsScrollProgress, firstColumnControls, secondColumnControls, thirdColumnControls]);

  return (
    <PageWrapper ref={targetRef}>
      <ScrollingText style={{ x: textX }}>
        DESIGN TECHNOLOGIST
      </ScrollingText>

      <HeroSection onMouseMove={handleMouseMove}>
        <ProfileImage
          src="/images/mattiabloise.png"
          alt="Mattia Bloise"
          initial={{ opacity: 0, scale: 1.1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ 
            duration: 1.5,
            ease: [0.43, 0.13, 0.23, 0.96]
          }}
        />
        
        <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 2 }}>
          <HeroContent>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
              <Typography 
                variant="h1" 
                sx={{ 
                  fontSize: { xs: '2rem', md: '4rem', lg: '6rem' },
                  fontWeight: 900,
                  lineHeight: 1,
                  mb: 4,
                  textShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
              >
                {t('about.title')}
              </Typography>
              <Typography 
                variant="h2" 
                sx={{ 
                  fontSize: { xs: '1.5rem', md: '2rem' },
                  fontWeight: 300,
                  textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              >
                {t('about.subtitle')}
              </Typography>
            </motion.div>
          </HeroContent>
        </Container>
      </HeroSection>

      <ContentSection ref={containerRef}>
        <Container maxWidth="xl">
          <Grid container spacing={8}>
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={firstColumnControls}
                transition={{ duration: 0.8 }}
                style={{ marginTop: '0px' }}
              >
                <Typography variant="overline" sx={{ opacity: 0.7, mb: 1 }}>
                  {t('about.sections.designDevelopment.title')}
                </Typography>
                <Typography variant="h4" sx={{ mb: 3, fontWeight: 500 }}>
                  {t('about.sections.designDevelopment.description')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { title: t('about.sections.designDevelopment.items.artDirection.title'), desc: t('about.sections.designDevelopment.items.artDirection.desc') },
                    { title: t('about.sections.designDevelopment.items.uiux.title'), desc: t('about.sections.designDevelopment.items.uiux.desc') },
                    { title: t('about.sections.designDevelopment.items.motion.title'), desc: t('about.sections.designDevelopment.items.motion.desc') },
                    { title: t('about.sections.designDevelopment.items.fullstack.title'), desc: t('about.sections.designDevelopment.items.fullstack.desc') }
                  ].map((item) => (
                    <Box key={item.title}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        {item.desc}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={secondColumnControls}
                transition={{ duration: 0.8 }}
                style={{ marginTop: '200px' }}
              >
                <Typography variant="overline" sx={{ opacity: 0.7, mb: 1 }}>
                  {t('about.sections.technologyInnovation.title')}
                </Typography>
                <Typography variant="h4" sx={{ mb: 3, fontWeight: 500 }}>
                  {t('about.sections.technologyInnovation.description')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { title: t('about.sections.technologyInnovation.items.ai.title'), desc: t('about.sections.technologyInnovation.items.ai.desc') },
                    { title: t('about.sections.technologyInnovation.items.interactive.title'), desc: t('about.sections.technologyInnovation.items.interactive.desc') },
                    { title: t('about.sections.technologyInnovation.items.ar.title'), desc: t('about.sections.technologyInnovation.items.ar.desc') }
                  ].map((item) => (
                    <Box key={item.title}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        {item.desc}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={thirdColumnControls}
                transition={{ duration: 0.8 }}
                style={{ marginTop: '400px' }}
              >
                <Typography variant="overline" sx={{ opacity: 0.7, mb: 1 }}>
                  {t('about.sections.strategyCreativity.title')}
                </Typography>
                <Typography variant="h4" sx={{ mb: 3, fontWeight: 500 }}>
                  {t('about.sections.strategyCreativity.description')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { title: t('about.sections.strategyCreativity.items.brand.title'), desc: t('about.sections.strategyCreativity.items.brand.desc') },
                    { title: t('about.sections.strategyCreativity.items.innovation.title'), desc: t('about.sections.strategyCreativity.items.innovation.desc') },
                    { title: t('about.sections.strategyCreativity.items.content.title'), desc: t('about.sections.strategyCreativity.items.content.desc') }
                  ].map((item) => (
                    <Box key={item.title}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        {item.desc}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </ContentSection>

      <ContentSection>
        <Container maxWidth="xl">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <Box sx={{ maxWidth: '800px', mb: 15 }}>
              <Typography variant="h2" sx={{ mb: 4, fontWeight: 500 }}>
                {t('about.approach.title')}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.25rem', opacity: 0.8 }}>
                {t('about.approach.description')}
              </Typography>
            </Box>
          </motion.div>
        </Container>
      </ContentSection>

      <ContentSection>
        <Container maxWidth="xl">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h2" sx={{ mb: 6, fontWeight: 500 }}>
              {t('about.certifications.title')}
            </Typography>
          </motion.div>
          <Grid container spacing={4}>
            {[
              { 
                title: t('about.certifications.items.webflow.title'), 
                desc: t('about.certifications.items.webflow.desc') 
              },
              { 
                title: t('about.certifications.items.adobe.title'), 
                desc: t('about.certifications.items.adobe.desc') 
              },
              { 
                title: t('about.certifications.items.motion.title'), 
                desc: t('about.certifications.items.motion.desc') 
              },
              { 
                title: t('about.certifications.items.fullstack.title'), 
                desc: t('about.certifications.items.fullstack.desc') 
              },
              { 
                title: t('about.certifications.items.shopify.title'), 
                desc: t('about.certifications.items.shopify.desc') 
              },
              { 
                title: t('about.certifications.items.figma.title'), 
                desc: t('about.certifications.items.figma.desc') 
              },
              { 
                title: t('about.certifications.items.wordpress.title'), 
                desc: t('about.certifications.items.wordpress.desc') 
              },
              { 
                title: t('about.certifications.items.seo.title'), 
                desc: t('about.certifications.items.seo.desc') 
              }
            ].map((cert, index) => (
              <Grid item xs={12} md={3} key={cert.title}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                >
                  <Box sx={{ 
                    p: 3, 
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    height: '100%'
                  }}>
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                      {cert.title}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      {cert.desc}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </ContentSection>

      <ContentSection>
        <Container maxWidth="xl"
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr', // Una colonna su mobile
            md: '1fr 4fr' // 1:4 su desktop
          },
          gap: { 
            xs: 3,  // Maggiore spaziatura su mobile
            md: 2 
          },
        }}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h2" sx={{ mb: 6, fontWeight: 500, minWidth: '300px', maxWidth: '300px', color: theme => theme.palette.text.primary }}>
              {t('about.clients.title')}
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              maxWidth: '1200px',
              display: 'flex',
              flexDirection: 'row',
              itemsAlign: 'center',
              gap: 0,
              typography: 'h3',
              fontWeight: 400,
              color: theme => theme.palette.text.secondary,
              '& > a': {
                color: theme => theme.palette.text.primary,
                textDecoration: 'none',
                transition: 'opacity 0.2s ease',
                '&:not(:last-child)::after': {
                  content: '","',
                  marginRight: 2,
                  opacity: 0.5
                }
              },
              '&:hover a': {  
                opacity: 0.4
              },
              '&:hover a:hover': {
                opacity: 1
              }
            }}>
              {clients.map((client) => (
                <Link 
                  key={client.name}
                  to={`/projects/client/${encodeURIComponent(client.name)}`}
                  style={{ textDecoration: 'none' }}
                >
                  {client.name}
                </Link>
              ))}
            </Box>
          </motion.div>
        </Container>
      </ContentSection>

      <ContentSection>
        <Container maxWidth="xl"
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr', // Una colonna su mobile
            md: '1fr 4fr' // 1:4 su desktop
          },
          gap: { 
            xs: 3,  // Maggiore spaziatura su mobile
            md: 2 
          },
        }}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h2" sx={{ mb: 6, fontWeight: 500, minWidth: '300px', maxWidth: '300px', color: theme => theme.palette.text.primary }}>
              {t('about.awards.title')}
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Box component="ul" sx={{ 
              listStyle: 'none', 
              padding: 0,
              maxWidth: '1200px',
              '&:hover li': {
                opacity: 0.4,
              },
              '&:hover li:hover': {
                opacity: 1,
              }
            }}>
              {awards
                .sort((a, b) => b.year - a.year)
                .map((award, index) => (
                  <motion.li
                    key={award._id}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Box 
                      component="li" 
                      sx={{ 
                        mb: 4, 
                        borderBottom: '1px solid', 
                        borderColor: 'divider', 
                        pb: 2,
                        display: 'grid',
                        gridTemplateColumns: '100px 1fr 1fr',
                        gap: 4,
                        alignItems: 'center',
                        textAlign: 'left',
                        transition: 'opacity 0.2s ease',
                      }}
                    >
                      <Typography variant="h4" sx={{ 
                        fontWeight: 500,
                        color: theme => theme.palette.text.primary,
                        textAlign: 'left'
                      }}>
                        {award.year}
                      </Typography>
                      <Typography variant="h4" sx={{ 
                        fontWeight: 500,
                        color: theme => theme.palette.text.primary,
                        textAlign: 'left'
                      }}>
                        {award.title}
                      </Typography>
                      <Link 
                        to={`/project/${award.projectSlug}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography variant="h4" sx={{ 
                          fontWeight: 500,
                          color: theme => theme.palette.text.primary,
                          textAlign: 'left'
                        }}>
                          {award.projectTitle}
                        </Typography>
                      </Link>
                    </Box>
                  </motion.li>
                ))}
            </Box>
          </motion.div>
        </Container>
      </ContentSection>

      <ContentSection>
        <CallToAction />
      </ContentSection>
    </PageWrapper>
  );
};

export default About;
