import React, { useEffect, useRef, useState } from 'react';
import { styled, GlobalStyles } from '@mui/material';
import { lerp } from '../utils/mouseUtils';

let clientX = -100;
let clientY = -100;

const CursorInner = styled('div')(({ size }) => ({
  position: 'fixed',
  width: `${size}px`,
  height: `${size}px`,
  backgroundColor: '#fff',
  mixBlendMode: 'difference',
  borderRadius: '50%',
  pointerEvents: 'none',
  zIndex: 11000,
  willChange: 'transform',
  marginLeft: `-${size / 2}px`,
  marginTop: `-${size / 2}px`,
  transition: 'width 0.2s ease, height 0.2s ease'
}));

const CustomCursor = () => {
  const cursorInnerRef = useRef(null);
  const innerDotRef = useRef({ x: -100, y: -100 });
  const mouseRef = useRef({ x: -100, y: -100 });
  const rafRef = useRef(null);
  const [cursorSize, setCursorSize] = useState(8);
  const isTouchDevice = useRef(
    'ontouchstart' in window || 
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );

  useEffect(() => {
    if (isTouchDevice.current) return;

    // Animazione del pallino interno
    const animateInnerDot = () => {
      if (cursorInnerRef.current) {
        innerDotRef.current.x = lerp(innerDotRef.current.x, mouseRef.current.x, 0.8);
        innerDotRef.current.y = lerp(innerDotRef.current.y, mouseRef.current.y, 0.8);
        
        cursorInnerRef.current.style.transform = `translate3d(${innerDotRef.current.x}px, ${innerDotRef.current.y}px, 0)`;
      }
      rafRef.current = requestAnimationFrame(animateInnerDot);
    };

    // Avvia l'animazione del pallino
    animateInnerDot();

    // Mouse move handler
    const onMouseMove = (e) => {
      mouseRef.current = { x: e.clientX, y: e.clientY };
      clientX = e.clientX;
      clientY = e.clientY;
    };

    document.addEventListener('mousemove', onMouseMove);

    // Gestione dell'ingrandimento del cursore
    const handleMouseEnter = () => setCursorSize(16);
    const handleMouseLeave = () => setCursorSize(8);

    // Aggiungi gli eventi a tutti gli elementi attivi
    const interactiveElements = document.querySelectorAll('button, a, [role="button"], [tabindex]');
    interactiveElements.forEach(element => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      document.removeEventListener('mousemove', onMouseMove);
      // Rimuovi gli eventi quando il componente viene smontato
      interactiveElements.forEach(element => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  if (isTouchDevice.current) {
    return null;
  }

  return (
    <>
      <GlobalStyles 
        styles={{
          '*': {
            cursor: 'none !important'
          }
        }} 
      />
      <CursorInner ref={cursorInnerRef} size={cursorSize} />
    </>
  );
};

export default CustomCursor;