import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  List,
  ListItem,
  Snackbar,
  Alert,
  CircularProgress,
  Input
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  PhotoCamera
} from '@mui/icons-material';
import axios from 'axios';

const CVManager = () => {
  const [cvData, setCvData] = useState({
    name: '',
    title: '',
    location: '',
    contact: { email: '', phone: '', portfolio: '' },
    defaultRecruiterText: '',
    workExperience: [],
    education: [],
    skills: [],
    languages: [],
    drivingLicense: [],
    imageUrl: ''
  });
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchCV();
  }, []);

  const validateData = () => {
    const newErrors = {};
    
    // Validazione campi base
    if (!cvData.name) newErrors.name = 'Il nome è obbligatorio';
    if (!cvData.title) newErrors.title = 'Il titolo è obbligatorio';
    if (!cvData.location) newErrors.location = 'La località è obbligatoria';
    
    // Validazione contatti
    if (!cvData.contact.email) newErrors['contact.email'] = 'Email obbligatoria';
    if (!cvData.contact.phone) newErrors['contact.phone'] = 'Telefono obbligatorio';
    if (!cvData.contact.portfolio) newErrors['contact.portfolio'] = 'Portfolio obbligatorio';

    // Validazione email formato
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (cvData.contact.email && !emailRegex.test(cvData.contact.email)) {
      newErrors['contact.email'] = 'Formato email non valido';
    }

    // Validazione esperienze lavorative
    cvData.workExperience.forEach((exp, index) => {
      if (!exp.company) newErrors[`workExperience.${index}.company`] = 'Azienda obbligatoria';
      if (!exp.position) newErrors[`workExperience.${index}.position`] = 'Posizione obbligatoria';
      if (!exp.period) newErrors[`workExperience.${index}.period`] = 'Periodo obbligatorio';
      if (!exp.location) newErrors[`workExperience.${index}.location`] = 'Località obbligatoria';
      
      // Validazione URL se presente
      if (exp.url) {
        try {
          new URL(exp.url);
        } catch (e) {
          newErrors[`workExperience.${index}.url`] = 'URL non valido';
        }
      }
    });

    // Validazione educazione
    cvData.education.forEach((edu, index) => {
      if (!edu.institution) newErrors[`education.${index}.institution`] = 'Istituto obbligatorio';
      if (!edu.degree) newErrors[`education.${index}.degree`] = 'Titolo di studio obbligatorio';
      if (!edu.period) newErrors[`education.${index}.period`] = 'Periodo obbligatorio';
      if (!edu.location) newErrors[`education.${index}.location`] = 'Località obbligatoria';
    });

    // Validazione competenze
    cvData.skills.forEach((skill, index) => {
      if (!skill.name) newErrors[`skills.${index}.name`] = 'Nome competenza obbligatorio';
      if (!skill.level) newErrors[`skills.${index}.level`] = 'Livello obbligatorio';
      if (skill.level < 1 || skill.level > 5) {
        newErrors[`skills.${index}.level`] = 'Il livello deve essere tra 1 e 5';
      }
      if (!skill.category) newErrors[`skills.${index}.category`] = 'Categoria obbligatoria';
      if (!['design', 'development', 'tools', 'soft'].includes(skill.category)) {
        newErrors[`skills.${index}.category`] = 'Categoria non valida';
      }
    });

    // Validazione lingue
    cvData.languages.forEach((lang, index) => {
      if (!lang.name) newErrors[`languages.${index}.name`] = 'Nome lingua obbligatorio';
      if (!lang.level) newErrors[`languages.${index}.level`] = 'Livello obbligatorio';
      
      // Validazione livello lingua (esempio: A1, A2, B1, B2, C1, C2)
      const validLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'Madrelingua'];
      if (lang.level && !validLevels.includes(lang.level)) {
        newErrors[`languages.${index}.level`] = 'Livello non valido (A1-C2 o Madrelingua)';
      }
    });

    // Validazione patenti
    cvData.drivingLicense.forEach((license, index) => {
      if (!license.type) newErrors[`drivingLicense.${index}.type`] = 'Tipo patente obbligatorio';
      
      // Validazione formato patente (esempio: A, B, C, D, BE, CE, DE)
      const validLicenses = ['A', 'B', 'C', 'D', 'BE', 'CE', 'DE', 'AM'];
      if (license.type && !validLicenses.includes(license.type.toUpperCase())) {
        newErrors[`drivingLicense.${index}.type`] = 'Tipo patente non valido';
      }
    });

    // Validazione defaultRecruiterText
    if (cvData.defaultRecruiterText && cvData.defaultRecruiterText.length > 500) {
      newErrors.defaultRecruiterText = 'Il testo non può superare i 500 caratteri';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchCV = async () => {
    try {
      const response = await axios.get('/api/cv');
      setCvData(response.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Errore nel caricamento del CV',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!validateData()) {
      setSnackbar({
        open: true,
        message: 'Correggi gli errori prima di salvare',
        severity: 'error'
      });
      return;
    }

    setSaving(true);
    try {
      await axios.put('/api/cv', cvData);
      setSnackbar({
        open: true,
        message: 'CV aggiornato con successo!',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Errore durante il salvataggio',
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };

  const handleAddWorkExperience = () => {
    setCvData({
      ...cvData,
      workExperience: [...cvData.workExperience, {
        company: '',
        position: '',
        period: '',
        location: '',
        description: '',
        url: ''
      }]
    });
  };

  const handleAddEducation = () => {
    setCvData({
      ...cvData,
      education: [...cvData.education, {
        institution: '',
        degree: '',
        period: '',
        location: ''
      }]
    });
  };

  const handleAddSkill = () => {
    setCvData({
      ...cvData,
      skills: [...cvData.skills, {
        name: '',
        level: 1,
        category: 'development'
      }]
    });
  };

  const handleAddLanguage = () => {
    setCvData({
      ...cvData,
      languages: [...cvData.languages, {
        name: '',
        level: ''
      }]
    });
  };

  const handleAddLicense = () => {
    setCvData({
      ...cvData,
      drivingLicense: [...cvData.drivingLicense, {
        type: ''
      }]
    });
  };

  const handleRemoveItem = (array, index) => {
    const newArray = [...cvData[array]];
    newArray.splice(index, 1);
    setCvData({ ...cvData, [array]: newArray });
  };

  const languageLevels = [
    { value: 'A1', label: 'A1 - Principiante' },
    { value: 'A2', label: 'A2 - Elementare' },
    { value: 'B1', label: 'B1 - Intermedio' },
    { value: 'B2', label: 'B2 - Intermedio superiore' },
    { value: 'C1', label: 'C1 - Avanzato' },
    { value: 'C2', label: 'C2 - Padronanza' },
    { value: 'Madrelingua', label: 'Madrelingua' }
  ];

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setCvData({
        ...cvData,
        imageUrl: response.data.url
      });
    } catch (error) {
      console.error('Errore upload immagine:', error);
      // Gestione errore
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component={Paper} sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Gestione CV
      </Typography>

      {/* Informazioni Base */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Informazioni Base</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nome"
                value={cvData.name}
                onChange={(e) => setCvData({...cvData, name: e.target.value})}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Titolo"
                value={cvData.title}
                onChange={(e) => setCvData({...cvData, title: e.target.value})}
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                value={cvData.location}
                onChange={(e) => setCvData({...cvData, location: e.target.value})}
                error={!!errors.location}
                helperText={errors.location}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Contatti */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Contatti</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Email"
                value={cvData.contact.email}
                onChange={(e) => setCvData({
                  ...cvData,
                  contact: { ...cvData.contact, email: e.target.value }
                })}
                error={!!errors['contact.email']}
                helperText={errors['contact.email']}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Telefono"
                value={cvData.contact.phone}
                onChange={(e) => setCvData({
                  ...cvData,
                  contact: { ...cvData.contact, phone: e.target.value }
                })}
                error={!!errors['contact.phone']}
                helperText={errors['contact.phone']}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Portfolio"
                value={cvData.contact.portfolio}
                onChange={(e) => setCvData({
                  ...cvData,
                  contact: { ...cvData.contact, portfolio: e.target.value }
                })}
                error={!!errors['contact.portfolio']}
                helperText={errors['contact.portfolio']}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Testo Recruiter */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Testo Recruiter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Testo Predefinito per Recruiter"
            value={cvData.defaultRecruiterText}
            onChange={(e) => setCvData({...cvData, defaultRecruiterText: e.target.value})}
            error={!!errors.defaultRecruiterText}
            helperText={errors.defaultRecruiterText}
          />
        </AccordionDetails>
      </Accordion>

      {/* Esperienze Lavorative */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Esperienze Lavorative</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cvData.workExperience.map((exp, index) => (
              <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Azienda"
                      value={exp.company}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], company: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.company`]}
                      helperText={errors[`workExperience.${index}.company`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Posizione"
                      value={exp.position}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], position: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.position`]}
                      helperText={errors[`workExperience.${index}.position`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Periodo"
                      value={exp.period}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], period: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.period`]}
                      helperText={errors[`workExperience.${index}.period`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Località"
                      value={exp.location}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], location: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.location`]}
                      helperText={errors[`workExperience.${index}.location`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      label="Descrizione"
                      value={exp.description}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], description: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.description`]}
                      helperText={errors[`workExperience.${index}.description`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="URL"
                      value={exp.url}
                      onChange={(e) => {
                        const newExp = [...cvData.workExperience];
                        newExp[index] = { ...newExp[index], url: e.target.value };
                        setCvData({ ...cvData, workExperience: newExp });
                      }}
                      error={!!errors[`workExperience.${index}.url`]}
                      helperText={errors[`workExperience.${index}.url`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => handleRemoveItem('workExperience', index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button startIcon={<AddIcon />} onClick={handleAddWorkExperience} variant="outlined" sx={{ mt: 2 }}>
            Aggiungi Esperienza
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Educazione */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Educazione</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cvData.education.map((edu, index) => (
              <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Istituzione"
                      value={edu.institution}
                      onChange={(e) => {
                        const newEdu = [...cvData.education];
                        newEdu[index] = { ...newEdu[index], institution: e.target.value };
                        setCvData({ ...cvData, education: newEdu });
                      }}
                      error={!!errors[`education.${index}.institution`]}
                      helperText={errors[`education.${index}.institution`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Titolo di Studio"
                      value={edu.degree}
                      onChange={(e) => {
                        const newEdu = [...cvData.education];
                        newEdu[index] = { ...newEdu[index], degree: e.target.value };
                        setCvData({ ...cvData, education: newEdu });
                      }}
                      error={!!errors[`education.${index}.degree`]}
                      helperText={errors[`education.${index}.degree`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Periodo"
                      value={edu.period}
                      onChange={(e) => {
                        const newEdu = [...cvData.education];
                        newEdu[index] = { ...newEdu[index], period: e.target.value };
                        setCvData({ ...cvData, education: newEdu });
                      }}
                      error={!!errors[`education.${index}.period`]}
                      helperText={errors[`education.${index}.period`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Località"
                      value={edu.location}
                      onChange={(e) => {
                        const newEdu = [...cvData.education];
                        newEdu[index] = { ...newEdu[index], location: e.target.value };
                        setCvData({ ...cvData, education: newEdu });
                      }}
                      error={!!errors[`education.${index}.location`]}
                      helperText={errors[`education.${index}.location`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => handleRemoveItem('education', index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button startIcon={<AddIcon />} onClick={handleAddEducation} variant="outlined" sx={{ mt: 2 }}>
            Aggiungi Educazione
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Skills */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Competenze</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cvData.skills.map((skill, index) => (
              <ListItem key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Nome Competenza"
                      value={skill.name}
                      onChange={(e) => {
                        const newSkills = [...cvData.skills];
                        newSkills[index] = { ...newSkills[index], name: e.target.value };
                        setCvData({ ...cvData, skills: newSkills });
                      }}
                      error={!!errors[`skills.${index}.name`]}
                      helperText={errors[`skills.${index}.name`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Livello (1-5)"
                      value={skill.level}
                      InputProps={{ inputProps: { min: 1, max: 5 } }}
                      onChange={(e) => {
                        const newSkills = [...cvData.skills];
                        newSkills[index] = { ...newSkills[index], level: parseInt(e.target.value) };
                        setCvData({ ...cvData, skills: newSkills });
                      }}
                      error={!!errors[`skills.${index}.level`]}
                      helperText={errors[`skills.${index}.level`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      select
                      label="Categoria"
                      value={skill.category}
                      onChange={(e) => {
                        const newSkills = [...cvData.skills];
                        newSkills[index] = { ...newSkills[index], category: e.target.value };
                        setCvData({ ...cvData, skills: newSkills });
                      }}
                      error={!!errors[`skills.${index}.category`]}
                      helperText={errors[`skills.${index}.category`]}
                    >
                      <MenuItem value="design">Design</MenuItem>
                      <MenuItem value="development">Development</MenuItem>
                      <MenuItem value="tools">Tools</MenuItem>
                      <MenuItem value="soft">Soft Skills</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => handleRemoveItem('skills', index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button startIcon={<AddIcon />} onClick={handleAddSkill} variant="outlined" sx={{ mt: 2 }}>
            Aggiungi Competenza
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Lingue */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Lingue</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cvData.languages.map((lang, index) => (
              <ListItem key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Lingua"
                      value={lang.name}
                      onChange={(e) => {
                        const newLangs = [...cvData.languages];
                        newLangs[index] = { ...newLangs[index], name: e.target.value };
                        setCvData({ ...cvData, languages: newLangs });
                      }}
                      error={!!errors[`languages.${index}.name`]}
                      helperText={errors[`languages.${index}.name`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      fullWidth
                      label="Livello"
                      value={lang.level}
                      onChange={(e) => {
                        const newLangs = [...cvData.languages];
                        newLangs[index] = { ...newLangs[index], level: e.target.value };
                        setCvData({ ...cvData, languages: newLangs });
                      }}
                      error={!!errors[`languages.${index}.level`]}
                      helperText={errors[`languages.${index}.level`]}
                    >
                      {languageLevels.map((level) => (
                        <MenuItem key={level.value} value={level.value}>
                          {level.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => handleRemoveItem('languages', index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button startIcon={<AddIcon />} onClick={handleAddLanguage} variant="outlined" sx={{ mt: 2 }}>
            Aggiungi Lingua
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Patenti */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Patenti</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {cvData.drivingLicense.map((license, index) => (
              <ListItem key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Tipo Patente"
                      value={license.type}
                      onChange={(e) => {
                        const newLicenses = [...cvData.drivingLicense];
                        newLicenses[index] = { ...newLicenses[index], type: e.target.value };
                        setCvData({ ...cvData, drivingLicense: newLicenses });
                      }}
                      error={!!errors[`drivingLicense.${index}.type`]}
                      helperText={errors[`drivingLicense.${index}.type`]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => handleRemoveItem('drivingLicense', index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button startIcon={<AddIcon />} onClick={handleAddLicense} variant="outlined" sx={{ mt: 2 }}>
            Aggiungi Patente
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Pulsante Salva */}
      <Button 
        variant="contained" 
        onClick={handleSave}
        disabled={saving}
        sx={{ mt: 3 }}
      >
        {saving ? <CircularProgress size={24} /> : 'Salva Modifiche'}
      </Button>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({...snackbar, open: false})}
      >
        <Alert 
          onClose={() => setSnackbar({...snackbar, open: false})} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box sx={{ mb: 3, textAlign: 'center' }}>
        {cvData.imageUrl && (
          <Box sx={{ 
            width: 150,
            height: 150,
            mx: 'auto',
            mb: 2,
            borderRadius: '50%',
            overflow: 'hidden'
          }}>
            <img 
              src={cvData.imageUrl} 
              alt="Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
        )}
        <label htmlFor="upload-photo">
          <Input
            accept="image/*"
            id="upload-photo"
            type="file"
            sx={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<PhotoCamera />}
          >
            {cvData.imageUrl ? 'Cambia Foto' : 'Carica Foto'}
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default CVManager; 