import { useState } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher';
import Logo from './Logo';
import styled from '@emotion/styled';

const menuVariants = {
  closed: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
      when: "afterChildren"
    }
  },
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
      when: "beforeChildren"
    }
  }
};

const menuItemVariants = {
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.4 }
    }
  }
};

const MotionBox = motion(Box);

// Componente BurgerMenu personalizzato
const BurgerMenu = styled('button')(({ theme, isOpen }) => ({
  width: '40px',
  height: '40px',
  position: 'relative',
  background: 'transparent',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1300,
  position: 'fixed',
  right: '20px',
  top: '20px',

  '&:hover span': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'
  },

  '& span': {
    display: 'block',
    width: '30px',
    height: '2px',
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
    position: 'absolute',
    transition: 'transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease',

    '&:first-of-type': {
      transform: isOpen 
        ? 'rotate(45deg)' 
        : 'translateY(-6px)',
      transformOrigin: 'center',
    },

    '&:last-of-type': {
      transform: isOpen 
        ? 'rotate(-45deg)' 
        : 'translateY(6px)',
      transformOrigin: 'center',
    }
  }
}));

// Componente MenuButton con Slide Up Reveal
const MenuButton = ({ text, onClick }) => {
  return (
    <Button
      color="inherit"
      onClick={onClick}
      sx={{ 
        position: 'relative',
        fontSize: { xs: '2.5rem', md: '4rem' },
        textTransform: 'uppercase',
        padding: 0,
        overflow: 'hidden',
        minHeight: 'auto',
        margin: 0,
        lineHeight: 1,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <motion.div
        initial={{ y: "0%" }}
        whileHover={{ y: "-100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ position: 'relative' }}
      >
        <span style={{ display: 'block' }}>{text}</span>
        <span style={{ 
          display: 'block', 
          position: 'absolute', 
          top: '100%', 
          left: 0,
          color: theme => theme.palette.primary.main 
        }}>
          {text}
        </span>
      </motion.div>
    </Button>
  );
};

const NavbarContainer = styled(AppBar)(({ theme, isfixed }) => ({
  position: isfixed === 'true' ? 'fixed' : 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  background: 'transparent',
  boxShadow: 'none',
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
  }
}));

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isHomePage = location.pathname === '/';

  const handleNavigation = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  // Array delle pagine dove vogliamo la navbar fixed
  const fixedNavbarPages = ['/about'];
  
  // Controlla se la pagina corrente dovrebbe avere la navbar fixed
  const isFixed = fixedNavbarPages.includes(location.pathname);

  return (
    <>
      {!isHomePage && (
        <NavbarContainer 
          position="sticky" 
          sx={{ 
            borderRadius: '0 !important',
            zIndex: 1200
          }} 
          color="transparent" 
          elevation={0}
          isfixed={isFixed.toString()}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Button 
                color="inherit" 
                onClick={() => navigate('/')}
                disableRipple
                sx={{ 
                  minWidth: 'auto',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.MuiButtonBase-root': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    textTransform: 'none',
                  },
                  '&.MuiButton-root': {
                    minWidth: 'unset',
                    padding: 0,
                    margin: 0
                  }
                }}
              >
                <Logo />
              </Button>
              <Box sx={{ width: '40px', height: '40px' }} />
            </Box>
          </Toolbar>
        </NavbarContainer>
      )}

      <BurgerMenu 
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        aria-label="Menu"
      >
        <span />
        <span />
      </BurgerMenu>

      <AnimatePresence mode="wait">
        {isOpen && (
          <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: {
                width: '100%',
                backgroundColor: 'background.default',
                backgroundImage: 'none',
                borderRadius: '0 !important',
                zIndex: 1250
              }
            }}
          >
            <MotionBox
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 3,
                position: 'relative'
              }}
            >
              <Box sx={{ 
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4
              }}>
                <MotionBox variants={menuItemVariants}>
                  <MenuButton 
                    text={t('navigation.projects')}
                    onClick={() => handleNavigation('/portfolio')}
                  />
                </MotionBox>

                <MotionBox variants={menuItemVariants}>
                  <MenuButton 
                    text={t('navigation.about')}
                    onClick={() => handleNavigation('/about')}
                  />
                </MotionBox>

                <MotionBox variants={menuItemVariants}>
                  <MenuButton 
                    text={t('navigation.contact')}
                    onClick={() => handleNavigation('/contact')}
                  />
                </MotionBox>

                <MotionBox variants={menuItemVariants}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1 
                    }}
                  >
                    <LanguageSwitcher />
                    <Box 
                      component="span" 
                      sx={{ 
                        opacity: 0.5,
                        mx: 1 
                      }}
                    >
                      /
                    </Box>
                    <ThemeSwitcher />
                  </Box>
                </MotionBox>

              </Box>
            </MotionBox>
          </Drawer>
        )}
      </AnimatePresence>
    </>
  );
}

export default Navbar;