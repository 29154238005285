import React, { useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';
import Projects from '../components/Projects';

// Wrapper motion per MUI components
const MotionContainer = motion(Container);
const MotionTypography = motion(Typography);

function ProjectsPage() {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('featured');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const titleVariants = {
    enter: {
      y: 20,
      opacity: 0,
      transition: { duration: 0.3 }
    },
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }
    },
    exit: {
      y: -20,
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <MotionContainer
      maxWidth="xl"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <Box sx={{ py: 4 }}>
        <AnimatePresence mode="wait">
          <MotionTypography
            key={selectedCategory}
            variant="megaTitle"
            component={motion.h1}
            initial="enter"
            animate="center"
            exit="exit"
            variants={titleVariants}
            sx={{
              mb: 4,
              background: theme => `linear-gradient(45deg, 
                ${theme.palette.text.primary} 30%, 
                ${alpha(theme.palette.text.primary, 0.7)} 90%
              )`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'left'
            }}
          >
            {t(`projects.dynamicTitles.${selectedCategory.toLowerCase()}`)}
          </MotionTypography>
        </AnimatePresence>

        <motion.div variants={itemVariants}>
          <Projects onCategoryChange={setSelectedCategory} />
        </motion.div>
      </Box>
    </MotionContainer>
  );
}

export default ProjectsPage;