import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ProjectGrid from './ProjectGrid';

const FilteredProjects = ({ filterType }) => {
  const { t } = useTranslation();
  const { value } = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        let response;
        
        switch(filterType) {
            case 'category':
              response = await axios.get(`/api/projects/by-category/${value}`);
              const categoryName = response.data[0]?.category?.name || '';
              setFilterName(categoryName);
              break;
            case 'activity':
              response = await axios.get(`/api/projects/by-activity/${value}`);
              const activityName = response.data[0]?.activities?.find(a => a._id === value)?.name || '';
              setFilterName(activityName);
              break;
            case 'agency':
              response = await axios.get(`/api/projects/by-agency/${value}`);
              setFilterName(value);
              break;
            default:
              response = await axios.get(`/api/projects/by-${filterType}/${value}`);
              setFilterName(value);
          }
        
        setProjects(response.data);
      } catch (error) {
        console.error('Errore nel caricamento progetti:', error);
        setError(t('projects.loadError'));
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [filterType, value, t]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">
          {t(`projects.filterBy${filterType.charAt(0).toUpperCase() + filterType.slice(1)}`)}:{' '}
          <strong>{filterName}</strong>
        </Typography>
        <Button onClick={() => navigate('/portfolio')} variant="outlined">
          {t('common.back')}
        </Button>
      </Box>
      
      <ProjectGrid projects={projects} />
    </Container>
  );
};

export default FilteredProjects; 