import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import gsap from 'gsap';
import Grid from './grid';
import Cursor from './cursor';
import { preloadImages } from './utils';
import './styles.css';

const GridContainer = styled('div')({
  position: 'fixed',
  top: '-5%',
  left: '-5%',
  width: '110%',
  height: '110vh',
  zIndex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(50, 2%)',
  gridTemplateRows: 'repeat(50, 2%)',
  pointerEvents: 'none',
  '@media (orientation: portrait)': {
    width: '200%',
    left: '-50%',
    transform: 'scale(0.7)',
    transformOrigin: 'center center'
  }
});

const getRandomPhotos = (totalPhotos, numToSelect) => {
  const allPhotos = Array.from({ length: totalPhotos }, (_, i) => i + 1);
  const selectedPhotos = [];
  
  // Fisher-Yates shuffle e selezione dei primi numToSelect elementi
  for (let i = allPhotos.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allPhotos[i], allPhotos[j]] = [allPhotos[j], allPhotos[i]];
  }
  
  return allPhotos.slice(0, numToSelect);
};

const generateGridHTML = (totalPhotos, numToShow) => {
  const selectedPhotos = getRandomPhotos(totalPhotos, numToShow);
  let htmlCode = '';
  
  selectedPhotos.forEach((photoNum, index) => {
    const photoPath = `/images/homepage/${photoNum}.jpg`;
    htmlCode += `
      <div class="grid__item pos-${index + 1}">
        <div class="grid__item-img" style="background-image:url(${photoPath});"></div>
      </div>
    `;
  });
  
  return htmlCode;
};

const PhotoGrid = () => {
  const gridRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    console.log('PhotoGrid mounted');
    
    if (gridRef.current) {
      console.log('Grid ref exists');
      const totalPhotos = 20; // Numero totale di foto disponibili
      const numToShow = 10;  // Numero di foto da mostrare
      gridRef.current.innerHTML = generateGridHTML(totalPhotos, numToShow);

      const gridItems = gridRef.current.querySelectorAll('.grid__item');
      console.log('Found grid items:', gridItems.length);
      
      gridItems.forEach((item, i) => {
        console.log(`Grid item ${i + 1}:`, {
          position: item.className,
          display: window.getComputedStyle(item).display,
          gridArea: window.getComputedStyle(item).gridArea
        });
      });

      preloadImages('.grid__item-img').then(() => {
        console.log('Images preloaded');
        document.body.classList.remove('loading');
        new Grid(gridRef.current);
      }).catch(err => {
        console.error('Error preloading images:', err);
      });

      if (cursorRef.current) {
        console.log('Initializing cursor');
        new Cursor(cursorRef.current);
      }
    }
  }, []);

  return (
    <>
      <GridContainer className="grid" ref={gridRef} />
      <div className="cursor" ref={cursorRef} />
    </>
  );
};

export default PhotoGrid; 