import { useEffect } from 'react';

const Analytics = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;

    // Google Analytics
    const loadGoogleAnalytics = () => {
      if (!process.env.REACT_APP_GA_ID) {
        console.warn('ID Google Analytics non configurato');
        return;
      }

      try {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
        script.onerror = () => console.error('Errore caricamento Google Analytics');
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', process.env.REACT_APP_GA_ID);
      } catch (error) {
        console.error('Errore inizializzazione Google Analytics:', error);
      }
    };

    // Hotjar
    const loadHotjar = () => {
      if (!process.env.REACT_APP_HOTJAR_ID) {
        console.warn('ID Hotjar non configurato');
        return;
      }

      try {
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:process.env.REACT_APP_HOTJAR_ID,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          r.onerror = () => console.error('Errore caricamento Hotjar');
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      } catch (error) {
        console.error('Errore inizializzazione Hotjar:', error);
      }
    };

    loadGoogleAnalytics();
    loadHotjar();
  }, []);

  return null;
};

export default Analytics; 