import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const CertWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden'
}));

const GridContainer = styled(motion.div)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: theme.spacing(3),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '1px',
    height: '100%',
    background: 'linear-gradient(180deg, transparent, rgba(255,255,255,0.1), transparent)',
  }
}));

const CertCard = styled(motion.div)(({ theme, $size = 'medium' }) => ({
  gridColumn: $size === 'large' ? 'span 6' : 'span 4',
  position: 'relative',
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.03)' 
    : 'rgba(0,0,0,0.03)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-10px)',
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.05)' 
      : 'rgba(0,0,0,0.05)',
    '& .cert-logo': {
      transform: 'scale(1.1)',
    }
  },
  [theme.breakpoints.down('md')]: {
    gridColumn: 'span 12',
  }
}));

const CertLogo = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain',
  marginBottom: '2rem',
  transition: 'transform 0.5s ease',
});

const FloatingShape = styled(motion.div)(({ theme, $color }) => ({
  position: 'absolute',
  width: '300px',
  height: '300px',
  borderRadius: '50%',
  background: `radial-gradient(circle, ${$color}20, transparent 70%)`,
  filter: 'blur(60px)',
  opacity: 0.5,
  pointerEvents: 'none',
}));

const CertificationsGrid = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1.2]);
  const shapeOpacity = useTransform(scrollYProgress, [0, 0.5, 1], [0.3, 0.6, 0.3]);

  const certificationData = [
    {
      title: 'Webflow Expert',
      logo: '/images/certifications/webflow.png',
      date: '2023',
      description: 'Certificazione avanzata in design e sviluppo con Webflow',
      skills: ['Custom Code', 'CMS', 'Animations'],
      color: '#4353FF',
      size: 'large',
      position: { top: '10%', left: '10%' }
    },
    {
      title: 'Adobe Creative Suite',
      logo: '/images/certifications/adobe.png',
      date: '2022',
      description: 'Certificazione professionale Adobe',
      skills: ['Photoshop', 'After Effects', 'XD'],
      color: '#FF4B4B',
      position: { top: '35%', left: '30%' }
    },
    {
      title: 'Google UX Design',
      logo: '/images/certifications/google.png',
      date: '2023',
      description: 'Professional Certificate in UX Design',
      skills: ['User Research', 'Prototyping', 'Testing'],
      color: '#00C853',
      position: { top: '60%', left: '50%' }
    },
    {
      title: 'Meta Front-End',
      logo: '/images/certifications/meta.png',
      date: '2023',
      description: 'Certificazione sviluppo front-end avanzato',
      skills: ['React', 'Performance', 'Accessibility'],
      color: '#0088CC',
      size: 'large',
      position: { top: '85%', left: '70%' }
    }
  ];

  return (
    <CertWrapper ref={containerRef}>
      {certificationData.map((cert, index) => (
        <FloatingShape
          key={`shape-${index}`}
          $color={cert.color}
          style={{
            ...cert.position,
            scale,
            opacity: shapeOpacity
          }}
        />
      ))}

      <Container maxWidth="xl">
        <motion.div style={{ opacity }}>
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 8,
              textAlign: 'center',
              fontWeight: 900,
              fontSize: { xs: '2.5rem', md: '4rem' }
            }}
          >
            Certificazioni
          </Typography>

          <GridContainer style={{ y }}>
            {certificationData.map((cert, index) => (
              <CertCard
                key={index}
                $size={cert.size}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ 
                  duration: 0.8,
                  delay: index * 0.2,
                  ease: [0.43, 0.13, 0.23, 0.96]
                }}
              >
                <CertLogo 
                  src={cert.logo} 
                  alt={cert.title}
                  className="cert-logo"
                />
                <Typography 
                  variant="h4" 
                  gutterBottom
                  sx={{ 
                    background: `linear-gradient(45deg, ${cert.color}, ${cert.color}88)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {cert.title}
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, opacity: 0.8 }}>
                  {cert.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                  {cert.skills.map((skill, i) => (
                    <Typography
                      key={i}
                      variant="caption"
                      sx={{
                        padding: '4px 12px',
                        borderRadius: '20px',
                        backgroundColor: `${cert.color}15`,
                        color: cert.color,
                        backdropFilter: 'blur(5px)'
                      }}
                    >
                      {skill}
                    </Typography>
                  ))}
                </Box>
                <Typography 
                  variant="overline" 
                  sx={{ 
                    opacity: 0.6,
                    display: 'block',
                    textAlign: 'right',
                    mt: 'auto'
                  }}
                >
                  {cert.date}
                </Typography>
              </CertCard>
            ))}
          </GridContainer>
        </motion.div>
      </Container>
    </CertWrapper>
  );
};

export default CertificationsGrid; 