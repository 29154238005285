import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import PhotoGrid from '../components/PhotoGrid/index';
import ShaderBackground from '../components/ShaderBackground';
import Footer from '../components/Footer';
import { useTheme } from '@mui/material/styles';



// Definizione dell'animazione di pulsazione
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    filter: ${({ theme }) => theme.palette.mode === 'dark' ? 'brightness(1)' : 'brightness(1) invert(1)'};
  }
  50% {
    transform: scale(1.05);
    filter: ${({ theme }) => theme.palette.mode === 'dark' ? 'brightness(1.1)' : 'brightness(1.1) invert(1)'};
  }
  100% {
    transform: scale(1);
    filter: ${({ theme }) => theme.palette.mode === 'dark' ? 'brightness(1)' : 'brightness(1) invert(1)'};
  }
`;

// Componente immagine stilizzato con l'animazione
const PulsingImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  borderRadius: 0,
  animation: `${pulseAnimation} 4s ease-in-out infinite`,
  willChange: 'transform, filter',
  filter: theme.palette.mode === 'dark' ? 'none' : 'invert(1)',
  maskImage: `
              linear-gradient(to right, 
                transparent, 
                black 0%, 
                black 75%, 
                transparent
              ),
              linear-gradient(to bottom, 
                transparent, 
                black 0%, 
                black 75%, 
                transparent
              )
            `,
            WebkitMaskImage: `
              linear-gradient(to right, 
                transparent, 
                black 0%, 
                black 75%, 
                transparent
              ),
              linear-gradient(to bottom, 
                transparent, 
                black 0%, 
                black 75%, 
                transparent
              )
            `,
  zIndex: 3,
  transition: 'filter 0.3s ease',
  padding: '1rem',

}));

function Home() {
  const theme = useTheme();

  useEffect(() => {
    // Imposta overflow: hidden quando il componente viene montato
    document.body.style.overflow = 'hidden';

    // Ripristina l'overflow originale quando il componente viene smontato
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <PhotoGrid />
      <Footer />
      <ShaderBackground />
      
        <Box 
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '450px',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            maskImage: `
              linear-gradient(to right, 
                transparent, 
                black 15%, 
                black 85%, 
                transparent
              ),
              linear-gradient(to bottom, 
                transparent, 
                black 15%, 
                black 85%, 
                transparent
              )
            `,
            WebkitMaskImage: `
              linear-gradient(to right, 
                transparent, 
                black 15%, 
                black 85%, 
                transparent
              ),
              linear-gradient(to bottom, 
                transparent, 
                black 15%, 
                black 85%, 
                transparent
              )
            `,
            maskComposite: 'intersect',
            WebkitMaskComposite: 'source-in',
            pointerEvents: 'none',
          }}
        > 
          <PulsingImage 
            src="/images/logo-payoff.svg" 
            alt="Logo"
          />
        </Box>
     
    </>
  );
}

export default Home; 