import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import ContactForm from '../components/ContactForm';
import SocialButtons from '../components/SocialButtons';
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{
          minHeight: 'calc(100svh - 120px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          
          py: { xs: 4, md: 8 }
        }}
      >
        <Typography 
          variant="megaTitle" 
          sx={{ 
            mb: { xs: 4, md: 6 },
            lineHeight: 1,
            textAlign: 'left'
          }}
        >
          {t('contact.title')}
        </Typography>

        <Box 
          sx={{
            width: '100%',
            
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: { xs: 8, md: 12 }
          }}
        >
          <Box sx={{ width: '100%' }}>
            <ContactForm />
          </Box>
          <SocialButtons />
        </Box>
      </Box>
    </Container>
  );
}

export default Contact; 