import React, { useEffect, useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import SocialButtons from './SocialButtons';
import axios from 'axios';
import PropTypes from 'prop-types';

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '1rem',
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  position: 'relative',
  display: 'inline-block',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  marginRight: '2rem',
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: 0,
    height: 1,
    backgroundColor: theme.palette.text.primary,
    transition: 'width 0.3s ease-in-out'
  },

  '&:hover::after': {
    width: '100%'
  }
}));

const MotionBox = motion(Box);
const MotionTypography = motion(Typography);

const CallToAction = ({ currentProject }) => {
  const [nextProject, setNextProject] = useState(null);
  
  useEffect(() => {
    const fetchNextProject = async () => {
      if (!currentProject) return;

      try {
        console.log('Current project:', currentProject);
        
        if (currentProject.relatedProjects && currentProject.relatedProjects.length > 0) {
          console.log('Fetching related project:', currentProject.relatedProjects[0]);
          const response = await axios.get(`/api/projects/${currentProject.relatedProjects[0]}`);
          setNextProject(response.data);
          return;
        }

        if (currentProject.category) {
          const categoryId = Array.isArray(currentProject.category) 
            ? (currentProject.category[0]._id || currentProject.category[0])
            : (currentProject.category._id || currentProject.category);

          console.log('Fetching by category:', categoryId);
          const response = await axios.get('/api/projects', {
            params: {
              category: categoryId,
              currentId: currentProject._id,
              isHidden: false
            }
          });
          
          if (response.data && response.data.length > 0) {
            const availableProjects = response.data.filter(p => 
              !p.isHidden && p._id !== currentProject._id
            );
            
            if (availableProjects.length > 0) {
              setNextProject(availableProjects[0]);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching next project:', error);
      }
    };

    fetchNextProject();
  }, [currentProject]);

  console.log('nextProject state:', nextProject);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <MotionBox
      component="section"
      sx={{ 
        py: { xs: 8, md: 12 },
        px: { xs: 2, md: 4 }
      }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={containerVariants}
    >
      {console.log('Rendering nextProject section:', !!nextProject)}
      
      {nextProject && !nextProject.isHidden && (
        <MotionBox 
          variants={itemVariants}
          sx={{ 
            mb: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="overline" sx={{ mb: 1, opacity: 0.7 }}>
            Next Project
          </Typography>
          <StyledLink 
            component={RouterLink} 
            to={`/project/${nextProject.slug}`}
            sx={{ 
              fontSize: '2rem',
              marginRight: 0,
              textAlign: 'center'
            }}
          >
            {nextProject.title}
          </StyledLink>
        </MotionBox>
      )}

      <MotionTypography 
        variant="megaTitle"
        sx={{ mb: 4 }}
        variants={itemVariants}
      >
        Let's Talk
      </MotionTypography>

      <MotionBox 
        variants={itemVariants}
        sx={{ mt: 3 }}
      >
        <StyledLink component={RouterLink} to="/contact">
          DROP AN EMAIL
        </StyledLink>
        <StyledLink href="https://calendly.com/mattiabloise/30min" target="_blank" rel="noopener">
          SCHEDULE A CALL
        </StyledLink>
        <MotionBox variants={itemVariants}>
          <SocialButtons/>
        </MotionBox>
      </MotionBox>
    </MotionBox>
  );
};

CallToAction.propTypes = {
  currentProject: PropTypes.shape({
    _id: PropTypes.string,
    category: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string
        })
      ])),
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string
      })
    ]),
    title: PropTypes.string,
    slug: PropTypes.string,
    isHidden: PropTypes.bool,
    relatedProjects: PropTypes.arrayOf(PropTypes.string)
  })
};

export default CallToAction; 