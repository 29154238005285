import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const MotionGrid = motion(Grid);

const ProjectGrid = ({ projects }) => {
  const theme = useTheme();

  const projectVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <Grid container spacing={3}>
      {projects.map((project, index) => (
        <MotionGrid 
          item 
          xs={12} 
          sm={6} 
          md={4} 
          key={project._id}
          component={motion.div}
          initial="hidden"
          animate="visible"
          variants={projectVariants}
          transition={{ delay: index * 0.1 }}
          whileHover={{ 
            scale: 1.05,
            transition: { duration: 0.2 }
          }}
        >
          <Card 
            component={Link} 
            to={`/project/${project.slug}`}
            elevation={0}
            sx={{ 
              height: '100%',
              textDecoration: 'none',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)'
              }
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={`${project.backgroundImage}`}
              alt={project.title}
              sx={{ objectFit: 'cover' }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {project.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {project.year} • {project.client}
              </Typography>
              {project.category && project.category.length > 0 && (
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {project.category.map((cat) => (
                    <Chip 
                      key={cat._id}
                      label={cat.name}
                      size="small"
                      sx={{ 
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'primary.main'
                          : 'primary.light',
                        color: theme.palette.mode === 'dark' 
                          ? 'primary.contrastText'
                          : 'primary.contrastText'
                      }} 
                    />
                  ))}
                </Box>
              )}
              {project.activities && project.activities.length > 0 && (
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {project.activities.slice(0, 2).map((activity) => (
                    <Chip 
                      key={activity._id}
                      label={activity.name}
                      size="small"
                      sx={{ 
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'rgba(0, 0, 0, 0.1)',
                        color: theme.palette.mode === 'dark' 
                          ? 'white'
                          : 'black',
                        '&:hover': {
                          backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.2)'
                            : 'rgba(0, 0, 0, 0.2)',
                        }
                      }} 
                    />
                  ))}
                  {project.activities.length > 2 && (
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.7)'
                          : 'rgba(0, 0, 0, 0.7)'
                      }}
                    >
                      +{project.activities.length - 2}
                    </Typography>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </MotionGrid>
      ))}
    </Grid>
  );
};

export default ProjectGrid; 