import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const ResultItem = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(0, 0, 0, 0.05)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    border: `1px solid ${theme.palette.primary.main}`,
    transform: 'translateX(8px)',
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(0, 0, 0, 0.05)',
  overflow: 'hidden',
  '& img': {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  }
}));

const ContentBox = styled(Box)({
  flex: 1,
  minWidth: 0, // Necessario per il text-overflow
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  marginBottom: theme.spacing(0.5),
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: 1.4,
}));

const SearchResult = ({ result, onSelect }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(result.url);
    onSelect?.();
  };

  return (
    <ResultItem
      onClick={handleClick}
      whileHover={{ x: 8 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.2 }}
    >
      {result.image && (
        <StyledAvatar
          src={`${result.image}`}
          variant="rounded"
          alt={result.title}
        />
      )}
      <ContentBox>
        <Title variant="h6" component="div">
          {result.title}
        </Title>
        <Subtitle>
          {result.year} • {result.client}
        </Subtitle>
        <Description>
          {result.description}
        </Description>
      </ContentBox>
    </ResultItem>
  );
};

export default SearchResult; 