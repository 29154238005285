import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    if (!credentials.username || !credentials.password) {
      setError('Inserisci username e password');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('/api/auth/login', credentials);
      
      if (response.data.user.role !== 'admin') {
        setError('Accesso non autorizzato');
        setLoading(false);
        return;
      }

      localStorage.setItem('adminToken', response.data.token);
      navigate('/admin/dashboard', { replace: true });
      
    } catch (error) {
      console.error('Errore completo:', error);
      setError(error.response?.data?.message || 'Credenziali non valide');
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 8,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: 'background.paper'
      }}
    >
      <Typography variant="h5" sx={{ mb: 3 }}>
        Accesso Amministratore
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <TextField
        fullWidth
        label="Username"
        margin="normal"
        value={credentials.username}
        onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
        autoComplete="username"
        disabled={loading}
      />

      <TextField
        fullWidth
        type="password"
        label="Password"
        margin="normal"
        value={credentials.password}
        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
        autoComplete="current-password"
        disabled={loading}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Accedi'}
      </Button>
    </Box>
  );
};

export default AdminLogin; 