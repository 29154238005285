import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Tabs,
  Tab,
  Chip,
  Autocomplete,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  Checkbox,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

const ProjectsManager = () => {
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [activities, setActivities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [projectsRes, activitiesRes, categoriesRes] = await Promise.all([
        axios.get('/api/projects'),
        axios.get('/api/activities'),
        axios.get('/api/categories')
      ]);
      
      setProjects(projectsRes.data);
      setActivities(activitiesRes.data);
      setCategories(categoriesRes.data);
    } catch (error) {
      console.error('Errore nel caricamento dati:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event, field) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);
    formData.append('projectTitle', currentProject.title || 'temp');
    formData.append('isBackground', field === 'backgroundImage');

    try {
      const response = await axios.post('/api/project-upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (field === 'pics') {
        setCurrentProject({
          ...currentProject,
          pics: [...(currentProject.pics || []), response.data.url]
        });
      } else {
        setCurrentProject({
          ...currentProject,
          [field]: response.data.url
        });
      }
    } catch (error) {
      console.error('Errore upload immagine:', error);
    }
  };

  const handleSave = async () => {
    try {
      const projectData = {
        ...currentProject,
        category: Array.isArray(currentProject.category) 
          ? currentProject.category 
          : currentProject.category 
            ? [currentProject.category]
            : []
      };

      console.log('Saving project data:', projectData);

      if (currentProject._id) {
        await axios.put(`/api/projects/${currentProject._id}`, projectData);
      } else {
        await axios.post('/api/projects', projectData);
      }
      
      setOpenDialog(false);
      fetchData();
    } catch (error) {
      console.error('Errore nel salvataggio:', error);
    }
  };

  const handleEdit = (project) => {
    setCurrentProject(project);
    setOpenDialog(true);
  };

  const handleDelete = async (projectId) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo progetto?')) {
      return;
    }

    try {
      await axios.delete(`/api/projects/${projectId}`);
      // Aggiorna la lista dei progetti dopo l'eliminazione
      setProjects(projects.filter(p => p._id !== projectId));
    } catch (error) {
      console.error('Errore durante l\'eliminazione:', error);
      alert('Errore durante l\'eliminazione del progetto');
    }
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 0: // Info Base
        return (
          <Grid container spacing={2}>
       
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Titolo"
                value={currentProject?.title || ''}
                onChange={(e) => setCurrentProject({...currentProject, title: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sottotitolo"
                value={currentProject?.subtitle || ''}
                onChange={(e) => setCurrentProject({...currentProject, subtitle: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Anno"
                value={currentProject?.year || ''}
                onChange={(e) => setCurrentProject({...currentProject, year: e.target.value})}
              />
            </Grid>
          </Grid>
        );

      case 1: // Contenuto
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Contenuto"
                value={currentProject?.content || ''}
                onChange={(e) => setCurrentProject({...currentProject, content: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Link Progetto"
                value={currentProject?.projectLink || ''}
                onChange={(e) => setCurrentProject({...currentProject, projectLink: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Link Video"
                value={currentProject?.videoLink || ''}
                onChange={(e) => setCurrentProject({...currentProject, videoLink: e.target.value})}
              />
            </Grid>
          </Grid>
        );

      case 2: // Dettagli
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Cliente"
                value={currentProject?.client || ''}
                onChange={(e) => setCurrentProject({...currentProject, client: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Agenzia"
                value={currentProject?.agency || ''}
                onChange={(e) => setCurrentProject({...currentProject, agency: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Realizzato presso"
                value={currentProject?.projectMadeAt || ''}
                onChange={(e) => setCurrentProject({...currentProject, projectMadeAt: e.target.value})}
              />
            </Grid>
          </Grid>
        );

      case 3: // Media
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Layout del Progetto</FormLabel>
                <RadioGroup
                  value={
                    currentProject?.isWide ? 'wide' : 
                    currentProject?.isTall ? 'tall' : 
                    'normal'
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    setCurrentProject({
                      ...currentProject,
                      isWide: value === 'wide',
                      isTall: value === 'tall'
                    });
                  }}
                >
                  <FormControlLabel 
                    value="normal" 
                    control={<Radio />} 
                    label="Layout Normale (quadrato)" 
                  />
                  <FormControlLabel 
                    value="wide" 
                    control={<Radio />} 
                    label="Layout Wide (doppia larghezza)" 
                  />
                  <FormControlLabel 
                    value="tall" 
                    control={<Radio />} 
                    label="Layout Tall (doppia altezza)" 
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Tipo di Media"
                value={currentProject?.mediaType || 'image'}
                onChange={(e) => setCurrentProject({
                  ...currentProject,
                  mediaType: e.target.value
                })}
              >
                <MenuItem value="image">Immagine</MenuItem>
                <MenuItem value="video">Video Loop</MenuItem>
              </TextField>
            </Grid>

            {currentProject?.mediaType === 'video' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="URL Video Loop"
                  value={currentProject?.videoUrl || ''}
                  onChange={(e) => setCurrentProject({
                    ...currentProject,
                    videoUrl: e.target.value
                  })}
                  helperText="URL del video MP4 per il loop in background"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Immagine di Sfondo</Typography>
                {currentProject?.backgroundImage && (
                  <img 
                    src={currentProject.backgroundImage}
                    alt="Background"
                    style={{ maxWidth: 200, marginBottom: 10 }}
                  />
                )}
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<ImageIcon />}
                >
                  Upload Background
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, 'backgroundImage')}
                  />
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1">Galleria Immagini</Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {currentProject?.pics?.map((pic, index) => (
                  <Box key={index} sx={{ position: 'relative' }}>
                    <img 
                      src={pic}
                      alt={`Gallery ${index}`} 
                      style={{ width: 100, height: 100, objectFit: 'cover' }} 
                    />
                    <IconButton
                      size="small"
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => {
                        const newPics = currentProject.pics.filter((_, i) => i !== index);
                        setCurrentProject({...currentProject, pics: newPics});
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<ImageIcon />}
                >
                  Aggiungi Immagine
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, 'pics')}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        );

      case 4: // Categorizzazione
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={currentProject?.isHidden || false}
                    onChange={(e) => setCurrentProject({
                      ...currentProject,
                      isHidden: e.target.checked
                    })}
                  />
                }
                label="Nascondi dalla lista principale"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={currentProject?.isFeatured || false}
                    onChange={(e) => setCurrentProject({
                      ...currentProject,
                      isFeatured: e.target.checked
                    })}
                  />
                }
                label="Mostra nei progetti principali"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={categories}
                getOptionLabel={(option) => option.name}
                value={currentProject?.category || []}
                onChange={(_, newValue) => {
                  console.log('Selected categories:', newValue);
                  setCurrentProject(prev => ({
                    ...prev,
                    category: newValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Categorie" />
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={activities}
                getOptionLabel={(option) => option.name}
                value={currentProject?.activities || []}
                onChange={(_, newValue) => {
                  setCurrentProject(prev => ({
                    ...prev,
                    activities: newValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Attività" />
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tags"
                value={currentProject?.tags?.join(', ') || ''}
                onChange={(e) => setCurrentProject({
                  ...currentProject,
                  tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
                })}
                helperText="Separa i tag con virgole"
              />
            </Grid>
          </Grid>
        );

      case 5: // Awards
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Awards e Riconoscimenti
              </Typography>
              {currentProject?.awards?.map((award, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Nome Award"
                        value={award.name}
                        onChange={(e) => {
                          const newAwards = [...currentProject.awards];
                          newAwards[index].name = e.target.value;
                          setCurrentProject({...currentProject, awards: newAwards});
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label="Anno"
                        value={award.year}
                        onChange={(e) => {
                          const newAwards = [...currentProject.awards];
                          newAwards[index].year = e.target.value;
                          setCurrentProject({...currentProject, awards: newAwards});
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Link"
                        value={award.link}
                        onChange={(e) => {
                          const newAwards = [...currentProject.awards];
                          newAwards[index].link = e.target.value;
                          setCurrentProject({...currentProject, awards: newAwards});
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <IconButton 
                        color="error"
                        onClick={() => {
                          const newAwards = currentProject.awards.filter((_, i) => i !== index);
                          setCurrentProject({...currentProject, awards: newAwards});
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  const newAwards = [...(currentProject.awards || []), { name: '', year: '', link: '' }];
                  setCurrentProject({...currentProject, awards: newAwards});
                }}
              >
                Aggiungi Award
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Box>
      <Button 
        variant="contained" 
        onClick={() => {
          setCurrentProject({});
          setOpenDialog(true);
        }}
        sx={{ mb: 2 }}
      >
        Nuovo Progetto
      </Button>

      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>Titolo</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Anno</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Featured</TableCell>
            <TableCell align="right">Azioni</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project._id}>
              <TableCell>{project.title}</TableCell>
              <TableCell>
                <IconButton
                  component={Link}
                  to={`/project/${project.slug}`}
                  size="small"
                  color="primary"
                  target="_blank"
                  sx={{ 
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                >
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>{project.year}</TableCell>
              <TableCell>{project.client}</TableCell>
              <TableCell>
                <Checkbox
                  checked={project.isFeatured || false}
                  disabled
                />
              </TableCell>
              <TableCell align="right">
                <IconButton 
                  onClick={() => handleEdit(project)}
                  color="primary"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  onClick={() => handleDelete(project._id)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {currentProject?._id ? 'Modifica Progetto' : 'Nuovo Progetto'}
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => setCurrentTab(newValue)}
            sx={{ mb: 2 }}
          >
            <Tab label="Info Base" />
            <Tab label="Contenuto" />
            <Tab label="Dettagli" />
            <Tab label="Media" />
            <Tab label="Categorizzazione" />
            <Tab label="Awards" />
          </Tabs>
          {renderTabContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Annulla</Button>
          <Button onClick={handleSave} variant="contained">Salva</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectsManager; 