import React from 'react';
import { Box, Typography } from '@mui/material';

function ProtectedArea() {
  return (
    <Box>
      <Typography variant="h1">Protected Area</Typography>
    </Box>
  );
}

export default ProtectedArea; 