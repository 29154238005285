import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ProjectsPage from './pages/Portfolio';
import ProtectedArea from './pages/ProtectedArea';
import ProjectDetail from './components/ProjectDetail';
import Navbar from './components/Navbar';
import './i18n';
import { CssBaseline, GlobalStyles } from '@mui/material';
import CustomCursor from './components/CustomCursor';
import CV from './components/CV';
import PageTransition from './components/PageTransition';
import SearchOverlay from './components/SearchOverlay';
import MaintenancePage from './components/MaintenancePage';
import axios from 'axios';
import usePreventContextMenu from './hooks/usePreventContextMenu';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import PrivateRoute from './components/PrivateRoute';
import Register from './components/Register';
import FilteredProjects from './components/FilteredProjects';
import Analytics from './components/Analytics';
import NotFound from './pages/NotFound';

// Componente wrapper per gestire le transizioni di pagina
function AppContent() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const location = useLocation();

  // Usa il hook per prevenire il menu contestuale
  usePreventContextMenu();

  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const response = await axios.get('/api/maintenance-status');
        setIsMaintenanceMode(response.data.maintenance);
      } catch (error) {
        console.error('Errore nel controllo dello stato di manutenzione:', error);
      }
    };

    checkMaintenanceStatus();
  }, []);

  if (isMaintenanceMode) {
    return (
      <>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              position: 'static',
              height: 'auto',
              overflow: 'visible'
            }
          }}
        />
        <CustomCursor />
        <MaintenancePage />
      </>
    );
  }

  return (
    <>
      <Analytics />
      <PageTransition key={location.pathname}>
        <CssBaseline enableColorScheme={false} />
        <GlobalStyles />
        <CustomCursor />
        <Navbar />
        <SearchOverlay />
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<ProjectsPage />} />
          <Route path="/project/:slug" element={<ProjectDetail />} />
          <Route path="/projects/client/:value" element={<FilteredProjects filterType="client" />} />
          <Route path="/projects/year/:value" element={<FilteredProjects filterType="year" />} />
          <Route path="/projects/agency/:value" element={<FilteredProjects filterType="agency" />} />
          <Route path="/projects/category/:value" element={<FilteredProjects filterType="category" />} />
          <Route path="/projects/activity/:value" element={<FilteredProjects filterType="activity" />} />
          <Route path="/protected" element={<ProtectedArea />} />
          <Route path="/cv" element={<CV />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route 
            path="/admin/dashboard/*" 
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            } 
          />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        
      </PageTransition>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;