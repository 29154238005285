import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProjectsManager from './admin/ProjectsManager';
import CVManager from './admin/CVManager';
import SettingsManager from './admin/SettingsManager';

const AdminDashboard = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAdmin = async () => {
      try {
        const response = await axios.get('/api/auth/verify');
        if (response.data.role !== 'admin') {
          navigate('/admin');
        }
      } catch (error) {
        console.error('Errore verifica admin:', error);
        navigate('/admin');
      }
    };

    verifyAdmin();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Pannello di Amministrazione
      </Typography>
      
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Progetti" />
        <Tab label="CV" />
        <Tab label="Impostazioni" />
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {currentTab === 0 && <ProjectsManager />}
        {currentTab === 1 && <CVManager />}
        {currentTab === 2 && <SettingsManager />}
      </Box>
    </Box>
  );
};

export default AdminDashboard; 