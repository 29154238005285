import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Grid, 
  Button,
  CircularProgress,
  Divider,
  Paper
} from '@mui/material';
import axios from 'axios';
import html2pdf from 'html2pdf.js';

const CV = () => {
  const [cvData, setCvData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCVData();
  }, []);

  const fetchCVData = async () => {
    try {
      const response = await axios.get('/api/cv');
      setCvData(response.data);
    } catch (error) {
      console.error('Error fetching CV data:', error);
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    const element = document.getElementById('cv-content');
    const opt = {
      margin: [0.5, 0.5],
      filename: 'MattiaBloise_CV.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      //jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      jsPDF: { unit: 'cm', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3}>
        <Box id="cv-content" sx={{ p: 4, bgcolor: 'background.paper' }}>
          {/* Header with Photo */}
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Box sx={{ 
              width: 200,
              height: 200,
              mx: 'auto',
              mb: 3,
              borderRadius: '50%',
              overflow: 'hidden',
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              boxShadow: 3
            }}>
              <img 
                src={cvData?.imageUrl} 
                alt={cvData?.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            </Box>
            
            <Typography variant="h3" sx={{ 
              mb: 1, 
              fontWeight: 'bold',
              fontSize: { xs: '2rem', md: '2.5rem' }
            }}>
              {cvData?.name}
            </Typography>
            <Typography variant="h5" sx={{ 
              mb: 2, 
              color: 'primary.main',
              fontSize: { xs: '1.5rem', md: '1.8rem' }
            }}>
              {cvData?.title}
            </Typography>
            <Typography sx={{ mb: 1 }}>{cvData?.location}</Typography>
            <Typography>{cvData?.contact.email}</Typography>
            <Typography>{cvData?.contact.phone}</Typography>
            <Typography sx={{ mb: 2 }}>{cvData?.contact.portfolio}</Typography>
            <Divider />
          </Box>

          {/* Recruiter Text */}
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ fontStyle: 'italic', textAlign: 'justify' }}>
              {cvData?.defaultRecruiterText}
            </Typography>
            <Divider sx={{ mt: 2 }} />
          </Box>

          {/* Work Experience Section */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              ESPERIENZA PROFESSIONALE
            </Typography>
            <Grid container spacing={3}>
              {cvData?.workExperience.map((job, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ 
                      color: 'primary.main',
                      fontSize: { xs: '1.1rem', md: '1.2rem' }
                    }}>
                      {job.company}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {job.position}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {job.period} | {job.location}
                    </Typography>
                    {job.description && (
                      <Typography sx={{ mt: 1 }}>{job.description}</Typography>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </Box>

          {/* Education Section */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              FORMAZIONE
            </Typography>
            <Grid container spacing={3}>
              {cvData?.education.map((edu, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ 
                      color: 'primary.main',
                      fontSize: { xs: '1.1rem', md: '1.2rem' }
                    }}>
                      {edu.institution}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {edu.degree}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {edu.period} | {edu.location}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </Box>

          {/* Skills */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              COMPETENZE
            </Typography>
            <Grid container spacing={3}>
              {['design', 'development', 'tools', 'soft'].map((category) => (
                <Grid item xs={12} md={6} key={category}>
                  <Typography variant="subtitle1" sx={{ 
                    mb: 1, 
                    fontWeight: 'bold',
                    textTransform: 'uppercase' 
                  }}>
                    {category}
                  </Typography>
                  {cvData?.skills
                    .filter(skill => skill.category === category)
                    .map((skill, index) => (
                      <Box key={index} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ flexGrow: 1 }}>{skill.name}</Typography>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          {[...Array(5)].map((_, i) => (
                            <Box
                              key={i}
                              sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                bgcolor: i < skill.level 
                                  ? 'primary.main'  // Pallini pieni - colore primario
                                  : (theme) => theme.palette.mode === 'dark' 
                                    ? 'grey.800'    // Pallini vuoti in tema scuro
                                    : 'grey.300',   // Pallini vuoti in tema chiaro
                                border: (theme) => theme.palette.mode === 'dark' 
                                  ? '1px solid grey.700'  // Bordo visibile in tema scuro
                                  : 'none',               // Nessun bordo in tema chiaro
                                transition: 'background-color 0.3s'
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Languages */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              LINGUE
            </Typography>
            <Grid container spacing={2}>
              {cvData?.languages.map((lang, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="subtitle1">
                    {lang.name} - {lang.level}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button 
          variant="contained" 
          onClick={generatePDF}
          size="large"
        >
          Scarica PDF
        </Button>
      </Box>
    </Container>
  );
};

export default CV; 