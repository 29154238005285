import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const AIWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(8, 0),
  overflow: 'hidden'
}));

const StaggeredGrid = styled(motion.div)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: theme.spacing(4),
  width: '100%',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '1px',
    height: '100%',
    background: 'linear-gradient(180deg, transparent, rgba(255,255,255,0.1), transparent)',
  }
}));

const AICard = styled(motion.div)(({ theme, $size = 'medium' }) => ({
  gridColumn: $size === 'large' ? 'span 8' : 'span 4',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.03)' 
    : 'rgba(0,0,0,0.03)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.1)' 
    : 'rgba(0,0,0,0.1)'}`,
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-10px)',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.05)' 
      : 'rgba(0,0,0,0.05)',
  }
}));

const AISection = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  const aiTools = [
    {
      name: 'ChatGPT & LLMs',
      description: 'Potenziamento del processo creativo attraverso lintelligenza artificiale conversazionale',
      icon: '🤖',
      size: 'large',
      examples: ['Ideazione', 'Ottimizzazione', 'Ricerca']
    },
    {
      name: 'Midjourney',
      description: 'Generazione e iterazione di concept visivi',
      icon: '🎨',
      examples: ['Concept Art', 'Visualizzazioni', 'Prototipi']
    },
    {
      name: 'AI Audio',
      description: 'Sintesi e manipolazione audio intelligente',
      icon: '🎵',
      examples: ['Sound Design', 'Voiceover', 'Musica']
    }
  ];

  return (
    <AIWrapper ref={containerRef}>
      <Container maxWidth="xl">
        <motion.div style={{ opacity }}>
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 8,
              textAlign: 'center',
              background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            AI-Powered Creation
          </Typography>

          <StaggeredGrid style={{ y }}>
            {aiTools.map((tool, index) => (
              <AICard
                key={index}
                $size={tool.size}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ 
                  duration: 0.8,
                  delay: index * 0.2,
                  ease: [0.43, 0.13, 0.23, 0.96]
                }}
              >
                <Typography variant="h1" sx={{ mb: 2, fontSize: '4rem' }}>
                  {tool.icon}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  {tool.name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, opacity: 0.8 }}>
                  {tool.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {tool.examples.map((example, i) => (
                    <Typography
                      key={i}
                      variant="caption"
                      sx={{
                        padding: '4px 12px',
                        borderRadius: '20px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        backdropFilter: 'blur(5px)'
                      }}
                    >
                      {example}
                    </Typography>
                  ))}
                </Box>
              </AICard>
            ))}
          </StaggeredGrid>
        </motion.div>
      </Container>
    </AIWrapper>
  );
};

export default AISection; 