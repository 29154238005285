import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

const LogoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  position: 'relative',
  overflow: 'hidden'
});

const LogoImage = styled('img')(({ theme }) => ({
  height: '16px',
  width: 'auto',
  borderRadius: '0 !important',
  filter: theme.palette.mode === 'light' ? 'invert(1)' : 'none'
}));

const LogoText = styled('span')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  textTransform: 'uppercase',
  letterSpacing: '-0.09px'
}));

const MotionDiv = styled(motion.div)({
  position: 'relative'
});

const Logo = () => {
  const theme = useTheme();

  return (
    <LogoWrapper>
      <MotionDiv
        initial={{ y: "0%" }}
        whileHover={{ y: "-100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <LogoImage
          src="/images/logo-no-payoff.svg" 
          alt="Mattia Bloise Logo"
        />
        <LogoText
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            opacity: theme.palette.mode === 'light' ? 0.6 : 0.4,
            height: '16px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          BACK TO HOME
        </LogoText>
      </MotionDiv>
    </LogoWrapper>
  );
};

export default Logo; 