import React from 'react';
import { Button, useTheme } from '@mui/material';
import { useColorMode } from '../contexts/ColorModeContext';
import { useTranslation } from 'react-i18next';

const ThemeSwitcher = () => {
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();
  const { t } = useTranslation();

  return (
    <Button
      onClick={toggleColorMode}
      color="inherit"
      sx={{ 
        minWidth: 'auto',
        padding: '6px 8px',
        fontSize: '1rem',
        fontWeight: 500
      }}
    >
      {theme.palette.mode === 'dark' 
        ? t('theme.light', 'LIGHT') 
        : t('theme.dark', 'DARK')}
    </Button>
  );
};

export default ThemeSwitcher; 