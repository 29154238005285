export default {
  navigation: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    projects: 'Projects'
  },
  home: {
    welcome: 'Welcome to my portfolio',
    subtitle: 'Creative Developer & Designer'
  },
  contact: {
    title: 'Let’s start something together.',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send Message',
    sending: 'Sending...',
    nameQuestion: 'What is your name?',
    emailQuestion: 'What is your email?',
    messageQuestion: 'What do you want to tell me?',
    review: 'Summary',
    confirm: 'Confirm',
    successMessage: 'Message sent successfully!',
    thankYouTitle: "Thank you for your message!",
    thankYouMessage: "I received your message and will respond as soon as possible.",
    sendAnother: "Send another message",
    errorTitle: "Oops! Something went wrong",
    errorDetailMessage: "An error occurred while sending the message. You can try again later or contact me directly via email or social media.",
    tryAgain: "Try again",
    alternativeContact: "Contact me on social"
  },
  project: {
    activities: 'Activities',
    visitProject: 'Visit Project',
    client: 'Client',
    agency: 'Agency',
    madeAt: 'Made at',
    notFound: 'Project not found'
  },
  projects: {
    title: 'Projects',
    filters: 'Filters',
    year: 'Year',
    activities: 'Activities',
    category: 'Category',
    client: 'Client',
    agency: 'Agency',
    allYears: 'All years',
    allActivities: 'All activities',
    allClients: 'All clients',
    allAgencies: 'All agencies',
    clearFilters: 'Clear filters',
    filterByClient: 'Projects by client',
    filterByYear: 'Projects by year',
    filterByAgency: 'Projects by agency',
    filterByCategory: 'Projects by category',
    filterByActivity: 'Projects by activity',
    loadError: 'Error loading projects',
    noProjects: 'No projects found',
    dynamicTitles: {
      featured: "Featured Projects",
      designed: "Projects that I've designed",
      animated: "Projects that I've animated",
      coded: "Projects that I've coded"
    }
  },
  about: {
    title: 'About me',
    subtitle: 'I am a multidisciplinary design technologist based in Italy with 20+ years of experience. I blend creativity and technology to deliver innovative solutions in design, animation, and development, collaborating with leading global brands across entertainment, luxury, and technology.',
    sections: {
      designDevelopment: {
        title: 'Design & Development',
        description: 'Over the years, I have mastered the art of blending aesthetics and functionality. From shaping intuitive user experiences to creating immersive animations, my goal is to craft meaningful, visually captivating, and effective solutions.',
        items: {
          artDirection: {
            title: 'Art Direction',
            desc: 'Guiding the vision and storytelling of every project.'
          },
          uiux: {
            title: 'UI/UX Design',
            desc: 'Designing interfaces that engage and inspire.'
          },
          motion: {
            title: 'Motion Design',
            desc: 'Bringing concepts to life through 2D and 3D animations.'
          },
          fullstack: {
            title: 'Full Stack Development',
            desc: 'Building responsive, high-performing web applications.'
          }
        }
      },
      technologyInnovation: {
        title: 'Technology & Innovation',
        description: 'I leverage the latest technologies, including AI tools like ChatGPT and MidJourney, to accelerate workflows, generate ideas, and refine solutions. By bridging innovation with strategy, I create impactful projects that adapt to evolving digital trends.',
        items: {
          ai: {
            title: 'AI-Assisted',
            desc: 'Integrating advanced tools to enhance speed and development.'
          },
          interactive: {
            title: 'Interactive Development',
            desc: 'Connecting sensors and devices digital and physical spaces seamlessly.'
          },
          ar: {
            title: '3D & AR',
            desc: 'Creating 3D models and animate, immersive experiences with 3D and AR technologies.'
          }
        }
      },
      strategyCreativity: {
        title: 'Strategy & Creativity',
        description: 'I believe in the power of storytelling and meaningful design to connect with audiences. By combining a deep understanding of brand identity and technological potential, I craft unique and compelling experiences.',
        items: {
          brand: {
            title: 'Brand Design',
            desc: 'Developing distinctive visual identities.'
          },
          innovation: {
            title: 'Digital Innovator',
            desc: 'Exploring emerging tools and techniques to deliver cutting-edge results.'
          },
          content: {
            title: 'Content Creator',
            desc: 'Creating engaging narratives that resonate with audiences.'
          }
        }
      }
    },
    approach: {
      title: 'Approach',
      description: 'I am committed to continuously improving my approach to creativity, technology, and innovation. While my work bridges digital and physical worlds, I deeply value projects with socio-cultural impact. By integrating cutting-edge tools and a multidisciplinary perspective, I believe consistent small actions can lead to meaningful, lasting transformations in both creative and professional landscapes.'
    },
    certifications: {
      title: 'Certifications & Skills',
      items: {
        webflow: {
          title: 'Webflow',
          desc: 'Certified Developer'
        },
        adobe: {
          title: 'Adobe Creative Suite',
          desc: '20+ years of experience'
        },
        motion: {
          title: 'Motion Design',
          desc: 'After Effects & Cinema 4D'
        },
        fullstack: {
          title: 'Full Stack Development',
          desc: 'React & NodeJSDeveloper'
        },
        shopify: {
          title: 'Shopify',
          desc: 'Developer'
        },
        figma: {
          title: 'Figma',
          desc: 'Advanced User Certification'
        },
        wordpress: {
          title: 'WordPress',
          desc: 'CMS & E-commerce Developer'
        },
        seo: {
          title: 'SEO',
          desc: 'Search Engine Optimization'
        }
      }
    },
    clients: {
      title: 'I Worked with'
    },
    awards: {
      title: 'Awards & Recognition'
    }
  },
  common: {
    loading: 'Loading...',
    back: 'Back',
    next: 'Next',
    rightClickDisabled: 'Right click disabled'
  },
  search: {
    placeholder: 'Start typing to search...',
    noResults: 'No results found',
    shortcut: 'CTRL+K',
    searchProjects: 'Search projects',
    recentSearches: 'Recent searches',
    clearHistory: 'Clear history'
  },
  maintenance: {
    title: 'Site under maintenance',
    subtitle: 'Working on improving the site',
    message: 'It will soon be back online with new and exciting features'
  },
  notFound: {
    title: '404',
    subtitle: 'Page Not Found',
    message: 'The page you are looking for does not exist or has been moved.',
    backHome: 'Back to Home'
  }
}; 