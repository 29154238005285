import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  Box, 
  CircularProgress, 
  Grid, 
  Chip,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const Projects = ({ onCategoryChange }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('featured');
  const theme = useTheme();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/projects');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error.response?.status === 503 
        ? 'Il sito è attualmente in manutenzione'
        : 'Errore nel caricamento dei progetti'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event, newCategory) => {
    setSelectedCategory(newCategory || 'featured');
    onCategoryChange?.(newCategory || 'featured');
  };

  const filteredProjects = projects.filter(project => {
    if (selectedCategory === 'featured') {
      return project.isFeatured && !project.isHidden;
    }
    return Array.isArray(project.category)
      ? project.category.some(cat => cat.name === selectedCategory)
      : project.category?.name === selectedCategory;
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const projectVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: [0.4, 0.0, 0.2, 1]
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.3
      }
    }
  };

  // Categorie fisse
  const categories = ['featured', 'Designed', 'Animated', 'Coded'];

  // Aggiungiamo una funzione per determinare la dimensione del grid item
  const getGridSize = (project) => {
    if (project.isWide) {
      return {
        xs: 12,          // mobile: full width
        sm: 12,          // tablet: full width
        md: 6,           // desktop: 2 colonne
        lg: 8           // large desktop: 2 colonne su 4
      };
    }
    return {
      xs: 12,
      sm: 6,
      md: 3,           // 4 colonne su desktop
      lg: 3            // 4 colonne su large desktop
    };
  };

  return (
    <Box 
      component="section"
      sx={{ 
        maxWidth: '1800px',
        margin: '0 auto',
        width: '100%'
      }}
    >
      <Box 
        sx={{ 
          mb: 4, 
          display: 'flex', 
          justifyContent: 'center',
          position: 'relative',
          px: { xs: 2, sm: 3, md: 4, lg: 6 }
        }}
      >
        <ToggleButtonGroup
          value={selectedCategory}
          exclusive
          onChange={handleCategoryChange}
          aria-label="category filter"
          sx={{
            position: 'relative',
            backdropFilter: 'blur(10px)',
            borderRadius: '100px',
            padding: '4px',
            '& .MuiToggleButton-root': {
              border: 'none',
              borderRadius: '100px',
              mx: 0.5,
              px: 3,
              py: 1,
              color: theme => theme.palette.text.secondary,
              fontSize: '0.9rem',
              fontWeight: 500,
              letterSpacing: '0.5px',
              textTransform: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: theme => alpha(theme.palette.primary.main, 0.08),
                transform: 'translateY(-1px)'
              },
              '&.Mui-selected': {
                background: theme => theme.palette.primary.main,
                color: theme => theme.palette.primary.contrastText,
                fontWeight: 600,
                '&:hover': {
                  background: theme => theme.palette.primary.main,
                  transform: 'translateY(-1px)'
                }
              }
            }
          }}
        >
          {categories.map((category) => (
            <ToggleButton 
              key={category} 
              value={category}
              component={motion.button}
              whileTap={{ scale: 0.97 }}
            >
              <AnimatePresence mode="wait">
                <motion.span
                  key={category}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {category === 'featured' ? 'Featured' : category}
                </motion.span>
              </AnimatePresence>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',              // Una colonna su mobile
            sm: '1fr',              // Una colonna su tablet piccoli
            md: 'repeat(4, 1fr)',   // 4 colonne su desktop
          },
          gap: 1.6,
          gridAutoRows: '400px',
          gridAutoFlow: 'dense',
          '& > *': {
            gridRow: props => props.isTall ? 'span 2' : 'span 1',
            gridColumn: props => props.isWide ? 'span 2' : 'span 1',
          }
        }}
      >
        {filteredProjects.map((project) => (
          <Card 
            component={Link} 
            to={`/project/${project.slug}`}
            key={project.projectId}
            sx={{ 
              gridRow: project.isTall ? 'span 2' : 'span 1',
              gridColumn: project.isWide ? 'span 2' : 'span 1',
              height: '100%',
              position: 'relative',
              backgroundColor: 'transparent',
              borderRadius: '12px',
              overflow: 'hidden',
              transition: 'transform 0.3s ease-out',
              '&:hover': {
                transform: 'translateY(-8px)'
              },
              '&:img': {
                transition: 'transform 0.3s ease-out',
                '&:hover': {
                  transform: 'scale(1.5)'
                }
              }
            }}
          >
            <Box 
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                transition: 'transform 0.3s ease-out',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            >
              {project.mediaType === 'video' ? (
                <Box 
                  component="video"
                  autoPlay
                  muted
                  loop
                  playsInline
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                >
                  <source src={project.videoUrl} type="video/mp4" />
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  image={project.backgroundImage}
                  alt={project.title}
                  sx={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              )}
            </Box>

            <Box 
              className="overlay"
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
                opacity: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                p: 4,
                zIndex: 2
              }}
            >
              <Typography 
                variant="overline"
                sx={{ 
                  color: 'rgba(255,255,255,0.8)',
               
                  letterSpacing: 2,
                  textTransform: 'uppercase'
                }}
              >
                {project.client} • {project.year}
              </Typography>
              <Typography 
                variant="h3"
                sx={{ 
                  color: 'white',
                  fontWeight: 700,
                  letterSpacing: -0.5,
                  mb: 2
                }}
              >
                {project.title}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {Array.isArray(project.category) 
                  ? project.category.map((cat, idx) => (
                    <Chip 
                      key={idx}
                      label={cat.name}
                      size="small"
                      sx={{ 
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        color: 'white',
                        borderRadius: '4px',
                        height: '24px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.75rem'
                        }
                      }}
                    />
                  ))
                  : project.category && (
                    <Chip 
                      label={project.category.name}
                      size="small"
                      sx={{ 
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        color: 'white',
                        borderRadius: '4px',
                        height: '24px',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.75rem'
                        }
                      }}
                    />
                  )
                }
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Projects;