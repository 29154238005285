import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Typography, 
  Chip, 
  CircularProgress, 
  Button,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import CallToAction from './CallToAction';
import { motion } from 'framer-motion';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StarIcon from '@mui/icons-material/Star';
import Video from './Video';
import ImageEffect from './PhotoPixelTrail';

const AnimatedTitle = ({ text }) => {
  // Configurazione delle animazioni
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { 
        staggerChildren: 0.04,
        delayChildren: 0.06 * i,
        ease: [0.2, 0.65, 0.3, 0.9]
      },
    }),
  };

  const character = {
    hidden: {
      opacity: 0,
      y: 20,
      rotateX: -90,
    },
    visible: {
      opacity: 1,
      y: 0,
      rotateX: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 200,
      },
    },
  };

  return (
    <Typography 
      variant="megaTitle" 
      component="div"
      sx={{ 
        width: '100vw',
        position: 'relative',
 /*        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        px: { xs: 2, md: 4 },
        mb: { xs: 4, md: 6 }, */
        textAlign: { xs: 'left', md: 'left' },
        wordBreak: 'keep-all',
        overflowWrap: 'normal',
        whiteSpace: 'normal',
        hyphens: 'none',
        perspective: '1000px',
        '& span': {
          display: 'inline-block',
          whiteSpace: 'pre',
          wordBreak: 'keep-all',
          overflowWrap: 'normal',
        },
        '& > div': {
          display: 'inline-flex',
          flexWrap: 'wrap',
          justifyContent: { xs: 'flex-start', md: 'center' },
          gap: '0.1em',
        }
      }}
    >
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        style={{ display: 'inline-block' }}
      >
        {text.split('').map((char, index) => (
          <motion.span
            key={index}
            variants={character}
            style={{ 
              display: 'inline-block',
              whiteSpace: 'pre',
              willChange: 'transform' // Ottimizza le performance
            }}
          >
            {char}
          </motion.span>
        ))}
      </motion.div>
    </Typography>
  );
};

const ProjectInfo = ({ label, value, linkTo, hideLabel }) => {
  if (!value) return null;
  
  const content = (
    <Typography variant="body1" sx={{ 
      fontWeight: 500,
      '&:hover': linkTo ? {
        textDecoration: 'underline',
        cursor: 'pointer'
      } : {}
    }}>
      {value}
    </Typography>
  );
  
  return (
    <Box>
      {hideLabel ? null : (
        <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
          {label}
        </Typography>
      )}
      {linkTo ? (
        <Link 
          to={linkTo} 
          style={{ 
            textDecoration: 'none', 
            color: 'inherit'
          }}
        >
          {content}
        </Link>
      ) : content}
    </Box>
  );
};

const ProjectDetail = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`/api/projects/by-slug/${slug}`);
        setProject(response.data);
      } catch (error) {
        console.error('Error fetching project:', error);
        setError('Errore nel caricamento del progetto');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrollingUp(currentScrollY < lastScrollY || currentScrollY < 100);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!project) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Typography>{t('project.notFound')}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Button
          component={Link}
          to="/portfolio"
          startIcon={<ArrowBackIcon />}
          sx={{
            mb: 3,
            position: 'relative',

            opacity: isScrollingUp ? 1 : 0,
            transition: 'all 0.3s ease-in-out',
            backgroundColor: theme => `${theme.palette.background.default}CC`,
            backdropFilter: 'blur(8px)',

            '&:hover': {
              backgroundColor: theme => 
                theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.1)' 
                  : 'rgba(0, 0, 0, 0.1)',
              transform: 'translateX(5px)'
            }
          }}
        >
          {t('common.back')}
        </Button>

        <AnimatedTitle text={project.title} />
        

        <Grid 
          container 
          spacing={3} 
          sx={{ 
            mb: 6,
            pt: 3,
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: { xs: 'auto', md: 'visible' },
            '& .MuiGrid-item': {
              flex: '1 0 auto',  // questo permette una distribuzione uniforme
              minWidth: { xs: '200px', md: '180px' }, // larghezza minima per ogni elemento
              maxWidth: { xs: '250px', md: '220px' }, // larghezza massima per ogni elemento
            },
            gap: 3  // aggiunge spazio uniforme tra gli elementi
          }}
        >
          {project.client && (
            <Grid item>
              <ProjectInfo 
                label="Client" 
                value={project.client} 
                linkTo={`/projects/client/${project.client}`}
              />
            </Grid>
          )}
          {project.year && (
            <Grid item>
              <ProjectInfo 
                label="Year" 
                value={project.year} 
                linkTo={`/projects/year/${project.year}`}
              />
            </Grid>
          )}
          {project.agency && (
            <Grid item>
              <ProjectInfo 
                label="Agency" 
                value={project.agency} 
                linkTo={`/projects/agency/${project.agency}`}
              />
            </Grid>
          )}
          {project.projectMadeAt && project.projectMadeAt !== project.agency && (
            <Grid item>
              <ProjectInfo 
                label="Made at" 
                value={project.projectMadeAt} 
                linkTo={`/projects/agency/${project.projectMadeAt}`}
              />
            </Grid>
          )}
          {project.category && project.category.length > 0 && (
            <Grid item>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                CATEGORY
              </Typography>
              {project.category.map(cat => (
                <ProjectInfo 
                  key={cat._id}
                  value={cat.name}
                  linkTo={`/projects/category/${cat.slug}`}
                  hideLabel
                />
              ))}
            </Grid>
          )}
          {project.activities && project.activities.length > 0 && (
            <Grid item sx={{ maxWidth: { xs: '300px', md: '350px' }}}>
              <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                Activities
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {project.activities.map((activity) => (
                  <Chip 
                    key={activity._id} 
                    label={activity.name}
                    component={Link}
                    to={`/projects/activity/${activity.name
                      .toLowerCase()
                      .replace(/\//g, '-')    // Sostituisce / con -
                      .replace(/\s+/g, '-')   // Sostituisce spazi con -
                      .replace(/[^\w-]+/g, '') // Rimuove caratteri speciali
                      .replace(/--+/g, '-')    // Sostituisce multipli - con singolo -
                      .trim()}`}
                    size="small"
                    clickable
                    sx={{ 
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(0, 0, 0, 0.1)',
                      color: 'inherit',
                      textDecoration: 'none',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'rgba(0, 0, 0, 0.2)',
                      }
                    }}
                  />
                ))}
              </Box>
            </Grid>
          )}
          
          {project.awards && project.awards.length > 0 && (
            <Grid item>
              <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                Awards
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {project.awards.map((award, index) => (
                  <Chip 
                    key={index}
                    label={`${award.name} ${award.year}`}
                    component={award.link ? "a" : "div"}
                    href={award.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    clickable={!!award.link}
                    size="small"
                    icon={<StarIcon />}
                    sx={{ 
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(0, 0, 0, 0.1)',
                      color: theme.palette.mode === 'dark' 
                        ? 'text.primary'
                        : 'inherit',
                      '& .MuiChip-icon': {
                        color: theme.palette.mode === 'dark' 
                          ? 'white'
                          : 'inherit'
                      }
                    }}
                  />
                ))}
              </Box>
            </Grid>
          )}
          {project.projectLink && (
            <Grid item sx={{ marginLeft: 'auto', minWidth: 'auto !important' }}>
              
              <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
               
              </Typography>
              <Button 
                variant="text" 
                href={project.projectLink}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon />}
                sx={{ 
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline'
                  }
                }}
              >
                Visit Project
              </Button>
            </Grid>
          )}
        </Grid>

        {project.videoLink && (
          <Video 
            url={project.videoLink}
            isPlaying={isPlaying}
            onPlay={handlePlayVideo}
            thumbnail={project.backgroundImage}
            title={project.title}
          />
        )}

        {project.subtitle && (
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 2,
                color: 'text.secondary',
                fontWeight: 300,
                fontSize: { xs: '1.5rem', md: '2rem' },
                opacity: 0.8
              }}
            >
              {project.subtitle}
            </Typography>
          )}
 

        <Typography variant="bodyLarge" sx={{ mb: 4 }}>
          {project.content}
        </Typography>
        


        {project.pics && project.pics.length > 0 && (
          <Box sx={{ mb: 4, mt: 4 }}>
            <Box container spacing={2}>
              {project.pics.map((pic, index) => (
                <Box item xs={12} md={6} key={index}>
                 
                  <Box
                    component="img"
                    src={`${pic}`}
                    alt={`${project.title} - Image ${index + 1}`}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '16px',
                      mb: 2
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <CallToAction currentProject={project} />
    </Container>
  );
};

export default ProjectDetail;