import React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const SocialList = styled('ul')({
  display: 'flex',
  gap: '1rem',
  listStyle: 'none',
  padding: 0,
  margin: 0
});

const SocialItem = styled('li')(({ theme }) => ({
  width: 40,
  height: 40,
}));

const SocialLink = styled('a')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',

  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontSize: '0.875rem',
  fontWeight: 500,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.mode === 'light' ? '#fff' : '#000',
    transform: 'translateY(-2px)'
  }
}));

const ContactLink = styled(RouterLink)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',

  color: theme.palette.primary.main,
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  '& svg': {
    width: '20px',
    height: '20px',
    fill: 'currentColor'
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.mode === 'light' ? '#fff' : '#000',
    transform: 'translateY(-2px)'
  }
}));

const EmailIcon = () => (
  <svg viewBox="0 0 20 16">
    <path d="M2,0C.9,0,0,.9,0,2V14c0,1.1,.9,2,2,2H18c1.1,0,2-.9,2-2V2c0-1.1-.9-2-2-2H2Zm0,2H18v1l-8,5L2,3v-1Zm0,3l8,5,8-5V14H2V5Z" />
  </svg>
);

const SocialButtons = () => {
  return (
    <SocialList className="socials" sx={{ ml: -1.5, mt: 3 }}>
      <SocialItem>
        <SocialLink 
          href="https://www.linkedin.com/in/mattiabloise" 
          target="_blank"
          rel="noopener noreferrer"
        >
          Li
        </SocialLink>
      </SocialItem>
      <SocialItem>
        <SocialLink 
          href="https://www.facebook.com/Blomat/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          Fb
        </SocialLink>
      </SocialItem>
      <SocialItem>
        <SocialLink 
          href="https://www.instagram.com/blomat" 
          target="_blank"
          rel="noopener noreferrer"
        >
          Ig
        </SocialLink>
      </SocialItem>
      
    </SocialList>
  );
};

export default SocialButtons; 