import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 2,
  textAlign: 'center',
  pointerEvents: 'none'
}));

const SearchHint = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 400,
  letterSpacing: '0.5px',
  opacity: 0.4,
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  textTransform: 'uppercase'
}));

const Footer = () => {
  return (
    <FooterWrapper>
      <SearchHint>
        Type to search...
      </SearchHint>
    </FooterWrapper>
  );
};

export default Footer; 