import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Alert, Snackbar, Typography } from '@mui/material';
import axios from 'axios';

function ContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: '', message: '' });
  const [submitted, setSubmitted] = useState({ status: false, success: false });

  const steps = [
    {
      field: 'name',
      question: t('contact.nameQuestion'),
      type: 'text'
    },
    {
      field: 'email',
      question: t('contact.emailQuestion'),
      type: 'email'
    },
    {
      field: 'message',
      question: t('contact.messageQuestion'),
      type: 'text',
      multiline: true
    },
    {
      type: 'review'
    }
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/messages/send', formData);
      setSubmitted({ status: true, success: true });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      setSubmitted({ status: true, success: false });
    } finally {
      setLoading(false);
    }
  };

  const handleStartOver = () => {
    setSubmitted({ status: false, success: false });
    setCurrentStep(0);
    setFormData({ name: '', email: '', message: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const renderStep = () => {
    const currentStepData = steps[currentStep];

    if (currentStepData.type === 'review') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: '100%',
            p: { xs: 3, md: 4 },
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            backdropFilter: 'blur(10px)'
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t('contact.review')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="overline" sx={{ opacity: 0.7 }}>
                {t('contact.name')}
              </Typography>
              <Typography variant="h6">
                {formData.name}
              </Typography>
            </Box>

            <Box>
              <Typography variant="overline" sx={{ opacity: 0.7 }}>
                {t('contact.email')}
              </Typography>
              <Typography variant="h6">
                {formData.email}
              </Typography>
            </Box>

            <Box>
              <Typography variant="overline" sx={{ opacity: 0.7 }}>
                {t('contact.message')}
              </Typography>
              <Typography variant="body1" sx={{ 
                whiteSpace: 'pre-wrap',
                lineHeight: 1.6
              }}>
                {formData.message}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <TextField
        fullWidth
        required
        name={currentStepData.field}
        label={currentStepData.question}
        type={currentStepData.type}
        multiline={currentStepData.multiline}
        rows={currentStepData.multiline ? 4 : 1}
        value={formData[currentStepData.field]}
        onChange={handleChange}
        margin="normal"
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '1.5rem',
            lineHeight: '1.8',
          },
          '& .MuiInputLabel-root': {
            fontSize: '1.5rem',
            '&.Mui-focused': {
              fontSize: '1rem'  // torna più piccolo quando è attivo
            }
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '2px'
            }
          }
        }}
      />
    );
  };

  const renderThankYouPage = () => {
    if (submitted.success) {
      return (
        <Box sx={{ textAlign: 'left', py: 4 }}>
          <h2>{t('contact.thankYouTitle')}</h2>
          <p>{t('contact.thankYouMessage')}</p>
          <Button 
            variant="contained" 
            onClick={handleStartOver}
            sx={{ mt: 2 }}
          >
            {t('contact.sendAnother')}
          </Button>
        </Box>
      );
    } else {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <h2>{t('contact.errorTitle')}</h2>
          <p>{t('contact.errorDetailMessage')}</p>
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="contained" 
              onClick={handleStartOver}
              sx={{ mr: 2 }}
            >
              {t('contact.tryAgain')}
            </Button>
            <Button 
              variant="outlined" 
              href="/social-links" 
            >
              {t('contact.alternativeContact')}
            </Button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box>
      {submitted.status ? (
        renderThankYouPage()
      ) : (
        <Box component="form" onSubmit={handleSubmit}>
          {renderStep()}
          
          <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
            {currentStep > 0 && (
              <Button onClick={handleBack}>
                {t('common.back')}
              </Button>
            )}
            
            {currentStep < steps.length - 1 ? (
              <Button 
                variant="contained"
                onClick={handleNext}
                disabled={!formData[steps[currentStep].field]}
              >
                {t('common.next')}
              </Button>
            ) : (
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleConfirmSubmit}
                disabled={loading}
              >
                {loading ? t('contact.sending') : t('contact.confirm')}
              </Button>
            )}
          </Box>

          <Snackbar 
            open={alert.show} 
            autoHideDuration={6000} 
            onClose={() => setAlert({ ...alert, show: false })}
          >
            <Alert severity={alert.type} onClose={() => setAlert({ ...alert, show: false })}>
              {alert.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Box>
  );
}

export default ContactForm;