export default {
  navigation: {
    home: 'Home',
    about: 'Chi Sono',
    contact: 'Contatti',
    projects: 'Progetti'
  },
  home: {
    welcome: 'Benvenuto nel mio portfolio',
    subtitle: 'Sviluppatore & Designer Creativo'
  },
  contact: {
    title: 'Iniziamo a fare qualcosa insieme.',
    name: 'Nome',
    email: 'Email',
    message: 'Messaggio',
    send: 'Invia Messaggio',
    nameQuestion: "Come ti chiami?",
    emailQuestion: "Qual è la tua email?",
    messageQuestion: "Cosa vorresti scrivermi?",
    review: "Riepilogo",
    confirm: 'Conferma',
    sending: 'Invio in corso...',
    successMessage: 'Messaggio inviato con successo!',
    thankYouTitle: "Grazie per il tuo messaggio!",
    thankYouMessage: "Ho ricevuto il tuo messaggio e ti risponderò il prima possibile.",
    sendAnother: "Invia un altro messaggio",
    errorTitle: "Ops! Qualcosa è andato storto",
    errorDetailMessage: "Si è verificato un problema durante l'invio del messaggio. Puoi riprovare più tardi o contattarmi direttamente tramite email o social network.",
    tryAgain: "Riprova",
    alternativeContact: "Contattami sui social"
  },
  project: {
    activities: 'Attività',
    visitProject: 'Visita il Progetto',
    client: 'Cliente',
    agency: 'Agenzia',
    madeAt: 'Realizzato presso',
    notFound: 'Progetto non trovato'
  },
  projects: {
    title: 'Progetti',
    filters: 'Filtri',
    year: 'Anno',
    activities: 'Attività',
    category: 'Categoria',
    client: 'Cliente',
    agency: 'Agenzia',
    allYears: 'Tutti gli anni',
    allActivities: 'Tutte le attività',
    allClients: 'Tutti i clienti',
    allAgencies: 'Tutte le agenzie',
    clearFilters: 'Cancella filtri',
    filterByClient: 'Progetti per cliente',
    filterByYear: 'Progetti dell\'anno',
    filterByAgency: 'Progetti realizzati presso',
    filterByCategory: 'Progetti nella categoria',
    filterByActivity: 'Progetti con attività',
    loadError: 'Errore nel caricamento dei progetti',
    noProjects: 'Nessun progetto trovato',
    dynamicTitles: {
      featured: "Progetti in evidenza",
      designed: "Progetti che ho disegnato",
      animated: "Progetti che ho animato",
      coded: "Progetti che ho codificato"
    }
  },
  about: {
    title: 'Chi Sono',
    subtitle: 'Sono un design technologist multidisciplinare con base in Italia e oltre 20 anni di esperienza. Unisco creatività e tecnologia per fornire soluzioni innovative nel design, animazione e sviluppo, collaborando con marchi globali leader nei settori dell\'intrattenimento, del lusso e della tecnologia.',
    sections: {
      designDevelopment: {
        title: 'Design & Sviluppo',
        description: 'Nel corso degli anni, ho perfezionato l\'arte di fondere estetica e funzionalità. Dalla creazione di esperienze utente intuitive alle animazioni immersive, il mio obiettivo è realizzare soluzioni significative, visivamente accattivanti ed efficaci.',
        items: {
          artDirection: {
            title: 'Art Direction',
            desc: 'Guidare la visione e lo storytelling di ogni progetto.'
          },
          uiux: {
            title: 'UI/UX Design',
            desc: 'Progettare interfacce che coinvolgono e ispirano.'
          },
          motion: {
            title: 'Motion Design',
            desc: 'Dare vita ai concetti attraverso animazioni 2D e 3D.'
          },
          fullstack: {
            title: 'Full Stack Development',
            desc: 'Costruire applicazioni web responsive e performanti.'
          }
        }
      },
      technologyInnovation: {
        title: 'Tecnologia & Innovazione',
        description: 'Utilizzo le ultime tecnologie, inclusi strumenti AI come ChatGPT e MidJourney, per accelerare i flussi di lavoro, generare idee e perfezionare le soluzioni. Unendo innovazione e strategia, creo progetti impattanti che si adattano alle tendenze digitali in evoluzione.',
        items: {
          ai: {
            title: 'AI-Assisted',
            desc: 'Integrare strumenti avanzati per migliorare velocità e sviluppo.'
          },
          interactive: {
            title: 'Sviluppo Interattivo',
            desc: 'Collegare sensori e dispositivi digitali e spazi fisici senza soluzione di continuità.'
          },
          ar: {
            title: '3D & AR',
            desc: 'Creare modelli 3D e animare, esperienze immersive con tecnologie 3D e AR.'
          }
        }
      },
      strategyCreativity: {
        title: 'Strategia & Creatività',
        description: 'Credo nel potere dello storytelling e del design significativo per connettersi con il pubblico. Combinando una profonda comprensione dell\'identità del brand e del potenziale tecnologico, creo esperienze uniche e coinvolgenti.',
        items: {
          brand: {
            title: 'Brand Design',
            desc: 'Sviluppare identità visive distintive.'
          },
          innovation: {
            title: 'Innovatore Digitale',
            desc: 'Esplorare strumenti e tecniche emergenti per fornire risultati all\'avanguardia.'
          },
          content: {
            title: 'Content Creator',
            desc: 'Creare narrative coinvolgenti che risuonano con il pubblico.'
          }
        }
      }
    },
    approach: {
      title: 'Approccio',
      description: 'Mi impegno a migliorare continuamente il mio approccio alla creatività, alla tecnologia e all\'innovazione. Mentre il mio lavoro unisce mondi digitali e fisici, do grande valore ai progetti con impatto socio-culturale. Integrando strumenti all\'avanguardia e una prospettiva multidisciplinare, credo che azioni piccole ma costanti possano portare a trasformazioni significative e durature sia nel panorama creativo che professionale.'
    },
    certifications: {
      title: 'Certificazioni & Competenze',
      items: {
        webflow: {
          title: 'Esperto Webflow',
          desc: 'Certificazione Sviluppo Avanzato'
        },
        adobe: {
          title: 'Adobe Creative Suite',
          desc: 'Professionista Certificato'
        },
        motion: {
          title: 'Motion Design',
          desc: 'Maestria in After Effects & Cinema 4D'
        },
        fullstack: {
          title: 'Sviluppo Full Stack',
          desc: 'Specialista in React & Next.js'
        },
        shopify: {
          title: 'Shopify',
          desc: 'Sviluppatore Certificato'
        },
        figma: {
          title: 'Figma',
          desc: 'Certificazione Utente Avanzato'
        },
        wordpress: {
          title: 'WordPress',
          desc: 'Specialista CMS & E-commerce'
        },
        seo: {
          title: 'SEO',
          desc: 'Ottimizzazione per i Motori di Ricerca'
        }
      }
    },
    clients: {
      title: 'Ho lavorato con',
    },
    awards: {
      title: 'Premi & Riconoscimenti'
    }
  },
  common: {
    loading: 'Caricamento...',
    back: 'Indietro',
    next: "Avanti",
    rightClickDisabled: 'Tasto destro disabilitato'
  },
  search: {
    placeholder: 'Inizia a digitare per cercare...',
    noResults: 'Nessun risultato trovato',
    shortcut: 'CTRL+K',
    searchProjects: 'Cerca progetti',
    recentSearches: 'Ricerche recenti',
    clearHistory: 'Cancella cronologia'
  },
  maintenance: {
    title: 'Sito Temporaneamente in Manutenzione',
    subtitle: 'In arrivo una nuova versione del sito',
    message: 'Tornerà presto online con nuove funzionalità.'
  },
  notFound: {
    title: '404',
    subtitle: 'Pagina Non Trovata',
    message: 'La pagina che stai cercando non esiste o è stata spostata.',
    backHome: 'Torna alla Home'
  }
  // ... altre traduzioni
}; 