import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoaderContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none'
});

const ContentWrapper = styled(motion.div)({
  width: '100%',
  minHeight: '100vh',
  opacity: 0 // Inizialmente nascosto
});

const WhiteLayer = styled(motion.div)({
  position: 'fixed', // Cambiato da absolute a fixed
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  zIndex: 9999
});

const BlackLayer = styled(motion.div)({
  position: 'fixed', // Cambiato da absolute a fixed
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#000000',
  zIndex: 9999
});

const LoadingNumber = styled(motion(Typography))({
  color: '#000000',
  fontSize: '15vw',
  fontWeight: 700,
  fontFamily: '"Montserrat", sans-serif',
  zIndex: 10000,
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
});

const PageTransition = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const contentRef = useRef(null);
  const resourcesRef = useRef([]);
  const loadedResourcesRef = useRef(0);

  useEffect(() => {
    setLoading(true);
    setProgress(0);
    setShowContent(false);
    loadedResourcesRef.current = 0;
    resourcesRef.current = [];

    const calculateProgress = () => {
      loadedResourcesRef.current += 1;
      const totalResources = Math.max(resourcesRef.current.length, 1);
      const currentProgress = (loadedResourcesRef.current / totalResources) * 100;
      setProgress(Math.min(currentProgress, 100));
    };

    // Simula un progresso minimo iniziale
    const minProgressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev < 30) return prev + 1;
        return prev;
      });
    }, 30);

    setTimeout(() => {
      if (contentRef.current) {
        // Raccogli immagini
        const images = Array.from(contentRef.current.getElementsByTagName('img'));
        images.forEach(img => {
          if (!img.complete) {
            resourcesRef.current.push(img);
            img.addEventListener('load', calculateProgress);
            img.addEventListener('error', calculateProgress);
          } else {
            loadedResourcesRef.current += 1;
          }
        });

        // Raccogli video
        const videos = Array.from(contentRef.current.getElementsByTagName('video'));
        videos.forEach(video => {
          if (!video.readyState >= 4) {
            resourcesRef.current.push(video);
            video.addEventListener('loadeddata', calculateProgress);
            video.addEventListener('error', calculateProgress);
          } else {
            loadedResourcesRef.current += 1;
          }
        });

        // Aggiungi font loading
        if (!document.fonts.ready) {
          resourcesRef.current.push('fonts');
          document.fonts.ready.then(() => {
            calculateProgress();
          });
        }

        // Se non ci sono risorse da caricare o sono tutte già cariche
        if (resourcesRef.current.length === 0) {
          clearInterval(minProgressInterval);
          setProgress(100);
        } else {
          calculateProgress();
        }
      }
    }, 100);

    // Timeout di sicurezza dopo 5 secondi
    const timeoutId = setTimeout(() => {
      clearInterval(minProgressInterval);
      setProgress(100);
    }, 5000);

    return () => {
      clearInterval(minProgressInterval);
      clearTimeout(timeoutId);
      if (contentRef.current) {
        const images = contentRef.current.getElementsByTagName('img');
        Array.from(images).forEach(img => {
          img.removeEventListener('load', calculateProgress);
          img.removeEventListener('error', calculateProgress);
        });

        const videos = contentRef.current.getElementsByTagName('video');
        Array.from(videos).forEach(video => {
          video.removeEventListener('loadeddata', calculateProgress);
          video.removeEventListener('error', calculateProgress);
        });
      }
    };
  }, [children]);

  useEffect(() => {
    if (progress >= 100) {
      // Riduciamo i tempi di attesa e sincronizziamo meglio le transizioni
      setTimeout(() => {
        setLoading(false);
        // Mostriamo il contenuto quasi immediatamente dopo l'inizio 
        // dell'animazione di uscita del loader
        setTimeout(() => {
          setShowContent(true);
        }, 200); // Ridotto da 800 a 200ms
      }, 200); // Ridotto da 500 a 200ms
    }
  }, [progress]);

  return (
    <>
      <AnimatePresence mode="wait">
        {loading && (
          <>
            <WhiteLayer
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '-100%' }}
              transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
              sx={{ borderRadius: '0 !important' }} 
            />
            
            <LoadingNumber
              variant="h1"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
            >
              {Math.round(progress)}%
            </LoadingNumber>

            <BlackLayer
              initial={{ height: '0%' }}
              animate={{ height: `${progress}%` }}
              exit={{ height: '100%' }}
              transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
              sx={{ borderRadius: '0 !important' }} 
            />
          </>
        )}
      </AnimatePresence>

      <ContentWrapper
        ref={contentRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: showContent ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </ContentWrapper>
    </>
  );
};

export default PageTransition;