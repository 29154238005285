import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  
  const toggleLanguage = () => {
    const newLang = i18n.language === 'it' ? 'en' : 'it';
    i18n.changeLanguage(newLang);
  };

  return (
    <Button
      onClick={toggleLanguage}
      color="inherit"
      sx={{ 
        minWidth: 'auto',
        padding: '6px 8px',
        fontSize: '1rem',
        fontWeight: 500
      }}
    >
      {i18n.language === 'it' ? 'ENG' : 'ITA'}
    </Button>
  );
}

export default LanguageSwitcher; 