import { useMemo, useEffect, useState } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { shaderMaterial, useTrailTexture } from '@react-three/drei'
import * as THREE from 'three'
import { useTheme } from '@mui/material/styles';
import { useColorMode } from '../contexts/ColorModeContext';
import { extend } from '@react-three/fiber'

// Materiale shader modificato per supportare il tema
const DotMaterial = shaderMaterial(
  {
    resolution: new THREE.Vector2(),
    mouseTrail: null,
    gridSize: 120,
    isDarkMode: 1.0, // 1.0 per dark, 0.0 per light
    circleCenter: new THREE.Vector2(-1, -1), // Centro del cerchio inizialmente fuori dallo schermo
    circleRadius: 0.0 // Raggio iniziale del cerchio
  },
  // Vertex shader rimane invariato
  `
    varying vec2 vUv;
    void main() {
      gl_Position = vec4(position.xy, 0.0, 1.0);
    }
  `,
  // Fragment shader modificato per supportare il tema
  `
    uniform vec2 resolution;
    uniform sampler2D mouseTrail;
    uniform float gridSize;
    uniform float isDarkMode;
    uniform vec2 circleCenter;
    uniform float circleRadius;

    vec2 coverUv(vec2 uv) {
      vec2 s = resolution.xy / max(resolution.x, resolution.y);
      vec2 newUv = (uv - 0.5) * s + 0.5;
      return clamp(newUv, 0.0, 1.0);
    }

    void main() {
      float aspect = resolution.x / resolution.y;
      vec2 screenUv = gl_FragCoord.xy / resolution;
      vec2 uv = coverUv(screenUv);

      // Correggiamo l'aspetto del cerchio per renderlo perfettamente tondo
      vec2 adjustedCenter = circleCenter;
      vec2 adjustedUv = screenUv;
      adjustedUv.x = (adjustedUv.x - 0.5) * aspect + 0.5;
      adjustedCenter.x = (adjustedCenter.x - 0.5) * aspect + 0.5;
      
      vec2 gridUv = fract(uv * gridSize);
      vec2 gridUvCenter = (floor(uv * gridSize) + 0.5) / gridSize;

      float trail = texture2D(mouseTrail, gridUvCenter).r;
      float color = isDarkMode > 0.5 ? trail : 1.0 - trail;
      
      // Calcola la distanza dal centro del cerchio usando la stessa griglia
      vec2 circleGridCenter = (floor(adjustedUv * gridSize) + 0.5) / gridSize;
      float dist = distance(circleGridCenter, adjustedCenter);
      
      // Applica l'effetto pixelato al cerchio
      if (dist < circleRadius) {
        color = isDarkMode > 0.5 ? 1.0 : 0.0;
      }

      gl_FragColor = vec4(vec3(color), 1.0);
    }
  `
)

extend({ DotMaterial })

function Scene() {
  const { size, viewport } = useThree()
  const theme = useTheme()
  const { toggleColorMode } = useColorMode()
  const [circle, setCircle] = useState({ center: new THREE.Vector2(-1, -1), radius: 0 })

  const dotMaterial = useMemo(() => {
    return new DotMaterial()
  }, [])

  // Aggiorna il materiale quando cambia il tema
  useEffect(() => {
    if (dotMaterial) {
      dotMaterial.isDarkMode = theme.palette.mode === 'dark' ? 1.0 : 0.0
    }
  }, [theme.palette.mode, dotMaterial])

  useEffect(() => {
    if (dotMaterial) {
      dotMaterial.circleCenter = circle.center
      dotMaterial.circleRadius = circle.radius
    }
  }, [circle, dotMaterial])

  const [trail, onMove] = useTrailTexture({
    size: 512,
    radius: 0.1,
    maxAge: 400,
    interpolate: 1,
    ease: function easeInOutCirc(x) {
      return x < 0.5 
        ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 
        : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2
    }
  })

  const scale = Math.max(viewport.width, viewport.height) / 2

  const handleClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent
    const x = offsetX / size.width
    const y = 1 - offsetY / size.height
    setCircle({ center: new THREE.Vector2(x, y), radius: 0 })

    // Anima il raggio del cerchio
    let radius = 0
    const interval = setInterval(() => {
      radius += 0.01 // Velocità ridotta per vedere meglio l'effetto
      setCircle((prev) => ({ ...prev, radius }))
      if (radius > 1.5) {
        clearInterval(interval)
        setTimeout(() => {
          toggleColorMode()
          setTimeout(() => {
            setCircle({ center: new THREE.Vector2(-1, -1), radius: 0 })
          }, 300)
        }, 300)
      }
    }, 16)
  }

  return (
    <mesh scale={[scale, scale, 1]} onPointerMove={onMove} onClick={handleClick}>
      <planeGeometry args={[2, 2]} />
      <primitive
        object={dotMaterial}
        gridSize={120}
        resolution={[size.width * viewport.dpr, size.height * viewport.dpr]}
        mouseTrail={trail}
        isDarkMode={theme.palette.mode === 'dark' ? 1.0 : 0.0}
        circleCenter={circle.center}
        circleRadius={circle.radius}
      />
    </mesh>
  )
}

export default function ShaderBackground() {
  const theme = useTheme()
  const [isTransitioning, setIsTransitioning] = useState(false)
  
  useEffect(() => {
    // Quando il tema cambia, gestiamo la transizione
    setIsTransitioning(true)
    const timer = setTimeout(() => {
      setIsTransitioning(false)
    }, 500) // Durata della transizione
    return () => clearTimeout(timer)
  }, [theme.palette.mode])

  return (
    <div style={{ 
      width: '100%', 
      height: '100vh', 
      position: 'fixed', 
      top: 0, 
      left: 0, 
      zIndex: 0,
      background: theme.palette.mode === 'dark' ? '#000' : '#fff',
    }}>
      {/* Canvas wrapper con opacity transition */}
      <div style={{
        width: '100%',
        height: '100%',
        opacity: isTransitioning ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }}>
        <Canvas
          gl={{
            antialias: true,
            powerPreference: 'high-performance'
          }}>
          <Scene />
        </Canvas>
      </div>
    </div>
  )
}
