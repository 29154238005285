import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const SkillsWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  overflow: 'hidden',
}));

const FloatingBackground = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.03,
  pointerEvents: 'none',
  background: `repeating-linear-gradient(
    45deg,
    ${theme.palette.text.primary},
    ${theme.palette.text.primary} 1px,
    transparent 1px,
    transparent 20px
  )`,
}));

const SkillCard = styled(motion.div)(({ theme, $featured }) => ({
  position: 'relative',
  padding: theme.spacing($featured ? 6 : 4),
  height: '100%',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.03)' 
    : 'rgba(0,0,0,0.03)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  gridColumn: $featured ? 'span 2' : 'span 1',
  overflow: 'hidden',
  '&:hover': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.05)' 
      : 'rgba(0,0,0,0.05)',
    transform: 'translateY(-10px)',
    '& .skill-icon': {
      transform: 'scale(1.1)',
    }
  }
}));

const SkillIcon = styled(motion.div)(({ theme }) => ({
  fontSize: '4rem',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.5s ease',
}));

const ProgressBar = styled(motion.div)(({ theme, $value }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '4px',
  width: '100%',
  background: `linear-gradient(90deg, 
    ${theme.palette.primary.main} 0%, 
    ${theme.palette.primary.main} ${$value}%, 
    rgba(255,255,255,0.1) ${$value}%
  )`,
}));

const skillsData = [
  {
    title: 'Animation',
    icon: '🎨',
    description: 'Creo animazioni 2D e 3D per siti web, app, social media, TV ed eventi. Dal motion design alle installazioni interattive.',
    expertise: 90,
    featured: true,
    tools: ['After Effects', 'Cinema 4D', 'Three.js', 'GSAP'],
    projects: 150
  },
  {
    title: 'Design',
    icon: '✏️',
    description: 'Dal concept al prodotto finale: logo design, UI/UX, packaging e brand identity.',
    expertise: 85,
    tools: ['Figma', 'Adobe CC', 'Webflow', 'Sketch'],
    projects: 200
  },
  {
    title: 'Coding',
    icon: '💻',
    description: 'Sviluppo full-stack con focus su esperienze web interattive e performanti.',
    expertise: 80,
    tools: ['React', 'Node.js', 'Three.js', 'WebGL'],
    projects: 120
  },
  {
    title: 'Interaction',
    icon: '🔄',
    description: 'Creazione di esperienze interattive che uniscono il mondo digitale e fisico.',
    expertise: 95,
    featured: true,
    tools: ['Arduino', 'Processing', 'TouchDesigner', 'Kinect'],
    projects: 80
  }
];

const SkillsSection = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);

  return (
    <SkillsWrapper ref={containerRef}>
      <FloatingBackground style={{ y: backgroundY }} />
      
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 8,
              textAlign: 'center',
              fontWeight: 900,
              fontSize: { xs: '2.5rem', md: '4rem' }
            }}
          >
            Competenze
          </Typography>

          <Grid container spacing={4}>
            {skillsData.map((skill, index) => (
              <Grid 
                item 
                xs={12} 
                md={skill.featured ? 8 : 4} 
                key={index}
              >
                <SkillCard
                  $featured={skill.featured}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ 
                    duration: 0.8,
                    delay: index * 0.2,
                    ease: [0.43, 0.13, 0.23, 0.96]
                  }}
                >
                  <SkillIcon className="skill-icon">
                    {skill.icon}
                  </SkillIcon>
                  
                  <Typography variant="h4" gutterBottom>
                    {skill.title}
                  </Typography>
                  
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 3,
                      opacity: 0.8,
                      maxWidth: skill.featured ? '70%' : '100%'
                    }}
                  >
                    {skill.description}
                  </Typography>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="overline" sx={{ opacity: 0.7 }}>
                      Tools & Technologies
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                      {skill.tools.map((tool, i) => (
                        <Typography
                          key={i}
                          variant="caption"
                          sx={{
                            padding: '4px 12px',
                            borderRadius: '20px',
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(5px)'
                          }}
                        >
                          {tool}
                        </Typography>
                      ))}
                    </Box>
                  </Box>

                  {skill.projects && (
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
                      {skill.projects}+ progetti completati
                    </Typography>
                  )}

                  <ProgressBar 
                    $value={skill.expertise}
                    initial={{ scaleX: 0 }}
                    whileInView={{ scaleX: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: 0.5 }}
                  />
                </SkillCard>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </SkillsWrapper>
  );
};

export default SkillsSection; 