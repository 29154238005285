import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  TextField, 
  Dialog, 
  DialogContent,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon, Keyboard as KeyboardIcon } from '@mui/icons-material'; 
import { motion, AnimatePresence } from 'framer-motion';
import SearchResult from './SearchResult';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { styled } from '@mui/material/styles';

// Stili personalizzati
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    minHeight: '100vh',
    alignItems: hasResults => hasResults ? 'flex-start' : 'center',
    paddingTop: hasResults => hasResults ? theme.spacing(2) : 0,
    transition: 'all 0.3s ease',
  },
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(0, 0, 0, 0.8)' 
      : 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '24px',
    padding: theme.spacing(2),
    maxWidth: '700px',
    width: '90%',
    margin: theme.spacing(2),
    boxShadow: theme.shadows[4],
    transform: hasResults => hasResults ? 'translateY(0)' : 'translateY(-10vh)',
    transition: 'all 0.3s ease',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(0, 0, 0, 0.5)' 
      : 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(4px)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '24px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)' 
      : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease',
    '&:hover, &.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.1)' 
        : 'rgba(0, 0, 0, 0.1)',
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 2),
    color: theme.palette.text.primary,
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.text.secondary,
  }
}));

const ShortcutBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)',
  borderRadius: '6px',
  fontSize: '12px',
  color: theme.palette.text.secondary,
}));

const SearchOverlay = () => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const isTypingInInput = useRef(false);
  const skipNextInput = useRef(false);
  const [hasResults, setHasResults] = useState(false);

  // Gestisce la pressione dei tasti in tutto il documento
  useEffect(() => {
    const handleKeyPress = (e) => {
      // Controlla se l'elemento attivo è un input o textarea
      const activeElement = document.activeElement;
      const isInputField = activeElement.tagName === 'INPUT' || 
                          activeElement.tagName === 'TEXTAREA' ||
                          activeElement.contentEditable === 'true';

      // Se è un campo di input, non aprire la ricerca
      if (isInputField && activeElement !== inputRef.current) {
        return;
      }

      // Apre con CTRL+K o CMD+K
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        e.preventDefault();
        setOpen(true);
        return;
      }

      // Verifica che e.key sia definito e sia un carattere singolo
      if (!open && 
          e.key && 
          typeof e.key === 'string' &&
          e.key.length === 1 && 
          !e.ctrlKey && 
          !e.metaKey && 
          !e.altKey && 
          !isInputField) {
        e.preventDefault();
        setOpen(true);
        skipNextInput.current = true;
        setTimeout(() => {
          setSearchTerm(e.key);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }

      // Chiude con ESC
      if (e.key === 'Escape') {
        setOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [open]);

  const handleInputChange = (e) => {
    if (skipNextInput.current) {
      skipNextInput.current = false;
      return;
    }
    setSearchTerm(e.target.value);
  };

  // Gestisce la chiusura quando si cancella tutto il testo
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && searchTerm === '') {
      handleClose();
    }
  };

  // Effettua la ricerca quando searchTerm cambia
  useEffect(() => {
    const searchContent = async () => {
      // Modifica qui: cerca solo se ci sono almeno 2 caratteri
      if (searchTerm.length < 2) {
        setResults([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`/api/search?q=${encodeURIComponent(searchTerm)}`);
        setResults(response.data);
      } catch (error) {
        console.error('Errore durante la ricerca:', error);
        setError(t('search.error'));
      } finally {
        setLoading(false);
      }
    };

    const debounceTimer = setTimeout(searchContent, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, t]);

  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
    setResults([]);
  };

  // Aggiorna hasResults quando cambiano i risultati
  useEffect(() => {
    setHasResults(searchTerm.length >= 2 && results.length > 0);
  }, [searchTerm, results]);

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      fullWidth
      hasResults={hasResults}
      TransitionComponent={motion.div}
      TransitionProps={{
        initial: { opacity: 0, y: 20 },
        animate: { 
          opacity: 1, 
          y: 0,
          transition: {
            duration: 0.3,
            ease: "easeOut"
          }
        },
        exit: { 
          opacity: 0, 
          y: 20,
          transition: {
            duration: 0.2,
            ease: "easeIn"
          }
        }
      }}
    >
      <DialogContent>
        <motion.div
          layout
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <StyledTextField
            inputRef={inputRef}
            autoFocus
            fullWidth
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={t('search.placeholder')}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: 28 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: 'rgba(255, 255, 255, 0.5)' }} />
                  ) : (
                    <ShortcutBadge>
                      <KeyboardIcon sx={{ fontSize: 16 }} />
                      <span>K</span>
                    </ShortcutBadge>
                  )}
                </InputAdornment>
              ),
              disableUnderline: true
            }}
          />
        </motion.div>

        <AnimatePresence mode="wait">
          <Box sx={{ 
            mt: searchTerm.length >= 2 ? 2 : 0
          }}>
            {searchTerm.length >= 2 && (
              <motion.div
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {error && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <Typography color="error" sx={{ textAlign: 'center', my: 2 }}>
                      {error}
                    </Typography>
                  </motion.div>
                )}

                {results.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ staggerChildren: 0.1 }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2,
                        maxHeight: '60vh',
                        overflowY: 'auto',
                        pr: 2,
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: 'rgba(255, 255, 255, 0.1)',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'rgba(255, 255, 255, 0.2)',
                          borderRadius: '4px',
                          '&:hover': {
                            background: 'rgba(255, 255, 255, 0.3)',
                          }
                        }
                      }}
                    >
                      {results.map((result, index) => (
                        <motion.div
                          key={result.id}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: index * 0.1 }}
                        >
                          <SearchResult 
                            result={result} 
                            onSelect={handleClose}
                          />
                        </motion.div>
                      ))}
                    </Box>
                  </motion.div>
                )}

                {!loading && results.length === 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                  >
                    <Typography 
                      color={theme => theme.palette.text.secondary}
                      sx={{ 
                        textAlign: 'center',
                        fontSize: '18px',
                        mt: 4 
                      }}
                    >
                      {t('search.noResults')}
                    </Typography>
                  </motion.div>
                )}
              </motion.div>
            )}
          </Box>
        </AnimatePresence>
      </DialogContent>
    </StyledDialog>
  );
};

export default SearchOverlay; 