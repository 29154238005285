import React, { useState, useEffect } from 'react';
import {
  Box,
  Switch,
  FormControlLabel,
  Typography,
  Paper,
  Button
} from '@mui/material';
import axios from 'axios';

const SettingsManager = () => {
  const [settings, setSettings] = useState({
    maintenanceMode: false,
    disableRegistration: true,
    enableSearch: true
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get('/api/settings');
      setSettings(response.data);
    } catch (error) {
      console.error('Errore nel caricamento impostazioni:', error);
    }
  };

  const handleSave = async () => {
    try {
      await axios.put('/api/settings', settings);
      alert('Impostazioni salvate con successo!');
    } catch (error) {
      console.error('Errore nel salvataggio impostazioni:', error);
      alert('Errore nel salvataggio delle impostazioni');
    }
  };

  return (
    <Box component={Paper} sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Impostazioni Generali
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={settings.maintenanceMode}
            onChange={(e) => setSettings({...settings, maintenanceMode: e.target.checked})}
          />
        }
        label="Modalità Manutenzione"
      />

      <FormControlLabel
        control={
          <Switch
            checked={settings.disableRegistration}
            onChange={(e) => setSettings({...settings, disableRegistration: e.target.checked})}
          />
        }
        label="Disabilita Registrazione"
      />

      <FormControlLabel
        control={
          <Switch
            checked={settings.enableSearch}
            onChange={(e) => setSettings({...settings, enableSearch: e.target.checked})}
          />
        }
        label="Abilita Ricerca"
      />

      <Box sx={{ mt: 3 }}>
        <Button 
          variant="contained" 
          onClick={handleSave}
        >
          Salva Impostazioni
        </Button>
      </Box>
    </Box>
  );
};

export default SettingsManager; 