import { createTheme } from '@mui/material/styles';

// Importa i font
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';

// Definizione delle costanti dei colori
const COLORS = {
  light: {
    primary: '#000000',
    secondary: '#78faae',
    background: '#ffffff',
    paper: '#f5f5f5'
  },
  dark: {
    primary: '#FFFFFF',
    secondary: '#f64b27',
    background: '#000000',
    paper: '#121212'
  }
};

// Costanti per border radius
const BORDER_RADIUS = {
  default: 16
};

// Tema base con le configurazioni comuni
const baseTheme = {
  typography: {
    fontSize: 16,
    fontFamily: '"Barlow", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '1.1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '0.9rem',
      fontWeight: 400,
    },
    body1: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    bodyLarge: {
      component: 'p',
      fontFamily: '"Barlow", sans-serif',
      fontSize: '1.618rem',
      fontWeight: 200,
      lineHeight: 1.2,
    },
    button: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    caption: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Barlow", sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    megaTitle: {
      component: 'h1',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 700,
      fontSize: 'clamp(1.5rem, 6vw, 5rem)',
      lineHeight: 1.3,
      letterSpacing: '-0.02em',
      wordBreak: 'keep-all',
      wordWrap: 'normal',
      overflowWrap: 'normal',
      hyphens: 'none',
      whiteSpace: 'normal',
      '@media (max-width: 600px)': {
        fontSize: 'clamp(1.5rem, 8vw, 3rem)',
        lineHeight: 1.2,
      }
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS.default,
  },
  components: {

  },
  
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,  // Modificato da 1536px (default) a 1800px
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS.default,
          // backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          '&.MuiPaper-elevation1': {
            boxShadow: 'none',
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS.default,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: BORDER_RADIUS.default,
          cursor: 'none !important',
          '&:hover': {
            cursor: 'none !important',
          },
          '@media (hover: none)': {
            '&:active': {
              transform: 'scale(0.98)',
              transition: 'transform 0.1s',
            }
          }
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: BORDER_RADIUS.default,
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            WebkitTextSizeAdjust: 'none',
            textSizeAdjust: 'none'
          },
          body: {
            WebkitTextSizeAdjust: 'none',
            textSizeAdjust: 'none'
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          cursor: 'none !important',
          borderRadius: BORDER_RADIUS.default,
          '&:hover': {
            cursor: 'none !important',
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          cursor: 'none !important',
          borderRadius: BORDER_RADIUS.default,
          '&:hover': {
            cursor: 'none !important',
          },
          '@media (hover: none)': {
            padding: '12px',
            minHeight: '44px',
            minWidth: '44px',
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          bodyLarge: 'p',
          megaTitle: 'h1',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          maskImage: 'linear-gradient(to bottom, black 0%, black 70%, transparent 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, black 0%, black 70%, transparent 100%)',
          background: ({ theme }) => 
            theme.palette.mode === 'dark' 
              ? 'rgba(0,0,0,0.5)'
              : 'rgba(255,255,255,0.5)',
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)',
          boxShadow: 'none',
          height: '120px',
          '& .MuiToolbar-root': {
            background: 'transparent',
            height: '100%'
          }
        }
      }
    }
  },
};

// Configurazioni specifiche per tema chiaro e scuro
const themes = {
  light: {
    palette: {
      mode: 'light',
      primary: {
        main: COLORS.light.primary,
      },
      secondary: {
        main: COLORS.light.secondary,
      },
      background: {
        default: COLORS.light.background,
        paper: COLORS.light.paper,
      },
      text: {
        primary: COLORS.light.primary,
        secondary: 'rgba(0, 0, 0, 0.7)',
      },
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: COLORS.dark.primary,
      },
      secondary: {
        main: COLORS.dark.secondary,
      },
      background: {
        default: COLORS.dark.background,
        paper: COLORS.dark.paper,
      },
      text: {
        primary: '#ffffff',
        secondary: 'rgba(255, 255, 255, 0.7)',
      },
    },
  },
};

export const getTheme = (mode) => createTheme({
  ...baseTheme,
  ...themes[mode],
}); 