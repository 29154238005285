import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { Box } from '@mui/material';

const Video = ({ url, isPlaying, onPlay, thumbnail, title = 'Video player' }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const isMP4 = url.endsWith('.mp4');

  // Inizializza Plyr solo per YouTube e Vimeo
  useEffect(() => {
    if (!isMP4 && !playerRef.current) {
      playerRef.current = new Plyr(videoRef.current, {
        controls: ['play', 'progress', 'mute'],
        clickToPlay: true,
        muted: false,
        resetOnEnd: false,
        hideControls: true,
        hideControlsOnMouseLeave: true,
        tooltips: { controls: false, seek: false }
      });

      playerRef.current.on('ready', () => {
        if (isPlaying && playerRef.current) {
          playerRef.current.play();
        }
      });

      playerRef.current.on('ended', () => {
        onPlay(false);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [isMP4]);

  // Gestisci il cambio di stato isPlaying
  useEffect(() => {
    if (isMP4) {
      // Per video MP4 usa l'API nativa
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    } else if (playerRef.current && isPlaying) {
      // Per YouTube/Vimeo usa Plyr
      playerRef.current.play();
    }
  }, [isPlaying, isMP4]);

  return (
    <Box sx={{ 
      position: 'relative',
      paddingTop: '56.25%',
      marginBottom: '2rem',
      backgroundColor: '#000',
      borderRadius: '8px',
      overflow: 'hidden',
      '--plyr-color-main': '#ffffff',
      '--plyr-control-radius': '4px',
      '--plyr-range-thumb-height': '0px',
      '--plyr-range-track-height': '2px',
      '--plyr-range-fill-background': '#ffffff',
      '--plyr-video-controls-background': 'transparent',
      '& .plyr__control': {
        backgroundColor: 'transparent !important',
      },
      '& .plyr__control:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
      },
      '& .plyr__control--pressed:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
      },
      '& .plyr__progress__container': {
        position: 'static',
        flex: 1,
        margin: '0 10px',
      },
      '& .plyr__progress': {
        margin: 0,
      },
      '& .plyr__progress input': {
        cursor: 'pointer',
      },
      '& .plyr__controls': {
        opacity: 0,
        transition: 'opacity 0.3s ease',
        padding: '15px',
        alignItems: 'center',
        gap: '10px',
      },
      '&:hover .plyr__controls': {
        opacity: 1,
      },
      '& .plyr__time': {
        display: 'none',
      },
      '& .plyr__menu': {
        display: 'none',
      }
    }}>
      {/* Video/Iframe container */}
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: isPlaying ? 1 : 0,
        transition: 'opacity 0.3s ease'
      }}>
        {isMP4 ? (
          // Player HTML nativo per MP4
          <video
            ref={videoRef}
            style={{ width: '100%', height: '100%' }}
            controls
            onEnded={() => onPlay(false)}
          >
            <source src={url} type="video/mp4" />
          </video>
        ) : url.includes('youtube.com') || url.includes('youtu.be') ? (
          // YouTube con Plyr
          <div 
            ref={videoRef}
            data-plyr-provider="youtube"
            data-plyr-embed-id={url.split('v=')[1] || url.split('/').pop()}
          />
        ) : (
          // Vimeo con Plyr
          <div
            ref={videoRef}
            data-plyr-provider="vimeo"
            data-plyr-embed-id={url.split('/').pop()}
          />
        )}
      </Box>

      {/* Thumbnail e pulsante play */}
      {!isPlaying && (
        <Box
          onClick={onPlay}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}
        >
          <Box
            component="img"
            src={thumbnail}
            alt={title}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '12px 24px',
            borderRadius: '24px',
            fontSize: '1.2rem',
            fontWeight: 500,
            backdropFilter: 'blur(4px)',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              transform: 'translate(-50%, -50%) scale(1.05)'
            }
          }}>
            Play
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Video; 