import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Typography 
          variant="h1" 
          sx={{ 
            fontSize: { xs: '6rem', md: '8rem' }, 
            marginBottom: 2,
            fontFamily: 'Montserrat',
            fontWeight: 600
          }}
        >
          404
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            marginBottom: 4,
            fontFamily: 'Montserrat',
            fontWeight: 500
          }}
        >
          {t('notFound.subtitle')}
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <Typography 
          variant="body1" 
          sx={{ 
            marginBottom: 4,
            color: theme.palette.text.secondary,
            maxWidth: '600px'
          }}
        >
          {t('notFound.message')}
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        <Button
          component={Link}
          to="/"
          variant="outlined"
          sx={{
            borderColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
            padding: '12px 24px',
            '&:hover': {
              borderColor: theme.palette.text.secondary,
              backgroundColor: 'rgba(255,255,255,0.05)'
            }
          }}
        >
          {t('notFound.backHome')}
        </Button>
      </motion.div>
    </Box>
  );
};

export default NotFound; 