import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const [authState, setAuthState] = useState({ 
    isLoading: true, 
    isAuthenticated: false 
  });

  useEffect(() => {
    const verifyAuth = async () => {
      const token = localStorage.getItem('adminToken');
      
      if (!token) {
        setAuthState({ isLoading: false, isAuthenticated: false });
        return;
      }

      try {
        const response = await axios.get('/api/auth/verify', {
          headers: {
            'x-auth-token': token
          }
        });

        console.log('Verifica risposta:', response.data);
        
        setAuthState({ 
          isLoading: false, 
          isAuthenticated: response.data.role === 'admin'
        });
      } catch (error) {
        console.error('Errore verifica:', error);
        localStorage.removeItem('adminToken');
        setAuthState({ isLoading: false, isAuthenticated: false });
      }
    };

    verifyAuth();
  }, []);

  if (authState.isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return authState.isAuthenticated ? children : <Navigate to="/admin" replace />;
};

export default PrivateRoute; 