import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <Box m={0} p={0}>
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        sx={{
          minHeight: '100svh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          bgcolor: 'background.default',
          color: 'text.primary'
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography variant="h1" gutterBottom>
            {t('maintenance.title')}
          </Typography>
          <Typography variant="h3" sx={{ mb: 4 }}>
            {t('maintenance.subtitle')}
          </Typography>
          <Typography variant="body1">
            {t('maintenance.message')}
          </Typography>
        </motion.div>
      </Box>
    </Box>
  );
};

export default MaintenancePage; 