import axios from 'axios';

// Configura l'URL base
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Interceptor per aggiungere il token a tutte le richieste
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    console.log('Request config:', {
      url: config.url,
      headers: config.headers
    });
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Modifica l'interceptor per la risposta
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Response error:', error.response);
    if (error.response?.status === 401 && !error.config.url.includes('/auth/verify')) {
      localStorage.removeItem('adminToken');
    }
    return Promise.reject(error);
  }
); 